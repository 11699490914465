import React, { useEffect, useState, useMemo, useRef } from 'react'
import union from 'lodash.union'
import debounce from 'lodash.debounce'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import SubscriptionPopup from 'components/SubscriptionPopup'

import Home from './Home'
import BlogPage from './BlogPage'
import { fetchBlogPosts } from './blog.module'

const Blog = (props) => {
  const [posts, setPosts] = useState([])
  const [tags, setTags] = useState([])
  const [query, setQuery] = useState('')
  const [filterTag, setFilterTag] = useState('')

  // extract tags from posts automatically
  const extractTags = () => {
    const res = posts.reduce((acc, cur) => union(acc, cur?.tags || []), [])

    setTags(res)
  }

  useMemo(extractTags, [posts])

  const trackSearch = (keyword) => {
    gtag(
      'event',
      keyword,
      {
        event_category: 'engagement',
        event_label: 'blog_search_term',
      },
    )
  }

  const debouncedTracker = useRef(debounce(trackSearch, 500))

  useEffect(() => {
    if (query.length > 2) {
      debouncedTracker.current(query)
    }
  }, [query])

  const onSearchChange = (e) => {
    setQuery(e.target.value)
  }

  const onTagChange = (e) => {
    gtag(
      'event',
      e.target.value,
      {
        event_category: 'engagement',
        event_label: 'blog_filter_tag',
      },
    )
    setFilterTag(e.target.value)
  }

  useEffect(() => {
    const getPosts = async () => {
      try {
        const res = await fetchBlogPosts()

        setPosts(res)
      } catch (err) {
        console.log(err)
      }
    }

    getPosts()
  }, [])

  return (
    <>
      <Route
        exact
        path="/blog"
        render={props => (
          <Home
            {...props}
            posts={posts}
            tags={tags}
            query={query}
            filterTag={filterTag}
            onSearchChange={onSearchChange}
            onTagChange={onTagChange}
          />
        )}
      />
      <Route
        path="/blog/:title"
        render={props => <BlogPage {...props} posts={posts} />}
      />
      <SubscriptionPopup />
    </>
  )
}

Blog.propTypes = {}
Blog.defaultProps = {}

export default Blog
