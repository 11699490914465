import React from 'react'
import contentful from 'utils/contentful'
import PDF from 'assets/icons/pdf.png'

export default async () => {
  let resources = []

  try {
    resources = await contentful.getEntries({
      content_type: 'resource',
    })

    resources = resources.items.map((post) => {
      const { sys, fields } = post

      return {
        ...fields,
        id: sys.id,
        createdAt: sys.createdAt,
        updatedAt: sys.updatedAt,
      }
    })
  } catch (err) {
    console.log(err)
  }
  return resources
}

export const fetchResource = async (urlPermalink) => {
  let resource = null

  try {
    const { items } = await contentful.getEntries({
      content_type: 'resource',
      'fields.urlPermalink': urlPermalink,
    })

    if (items[0]) {
      const { fields, sys } = items[0]

      resource = {
        ...fields,
        id: sys.id,
        createdAt: sys.createdAt,
        updatedAt: sys.updatedAt,
      }
    } // eslint-disable-line
  } catch (err) {
    console.log(err)
  }

  return resource
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}
