import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from 'components/Typography'
import BlogHighlightItem from './BlogHighlightItem'

const useStyles = makeStyles((theme) => ({
  header: {
    margin: '40px 0px',
  },
}))

const RecentBlogPosts = (props) => {
  const { posts } = props
  const classes = useStyles()
  const matchesTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const highlightedPosts = posts.filter((x) => x.highlight)

  const postsOnCurrentPage = highlightedPosts.slice(0, 3)

  return (
    postsOnCurrentPage.length
      ? (
        <>
          <Typography className={classes.header} color="textSecondary" weight="light" variant={matchesTablet ? 'h3' : 'h2'}>Highlights</Typography>
          {
            postsOnCurrentPage.map((x, i) => (
              <BlogHighlightItem light key={x.id} post={x} tabIndex={i + 1} />
            ))
          }
        </>
      )
      : null
  )
}

RecentBlogPosts.propTypes = {}
RecentBlogPosts.defaultProps = {}

export default RecentBlogPosts
