import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import createPostURL from 'utils/createPostURL'

import Grid from '@material-ui/core/Grid'
import Typography from 'components/Typography'
import LazyImage from 'components/LazyImage'
import Card from 'components/Card'
import CardHover from 'components/Card/CardHover'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '80px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: '40px 0px',
    },
  },
  header: {
    marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16,
    },
  },
  card: {
    padding: 12,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}))

const BlogMorePosts = (props) => {
  const { posts } = props
  const classes = useStyles()
  const matchesTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <div className={classes.root}>
      <Typography className={classes.header} weight="bold" color="secondary" variant="h3">More from Red Thread Ventures</Typography>
      <Grid container spacing={2}>
        {
          posts.map(x => (
            <Grid key={x.id} item xs={6} sm={4}>
              <CardHover disabled={matchesTablet}>
                <Card className={classes.card} to={createPostURL(x.urlPermalink)}>
                  <Typography>{x.title}</Typography>
                  <br />
                  <LazyImage
                    src={x.heroImage.file.url}
                    alt={x.title}
                  />
                </Card>
              </CardHover>
            </Grid>
          ))
        }
      </Grid>
    </div>
  )
}

BlogMorePosts.propTypes = {
  // posts is at most an array of length 3
  posts: PropTypes.arrayOf(PropTypes.object),
}
BlogMorePosts.defaultProps = {
  posts: [],
}

export default BlogMorePosts
