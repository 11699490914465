import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import RecentBlogPosts from '../components/RecentBlogPosts'
import BlogHighlights from '../components/BlogHighlights'
import BlogSearchResults from '../components/BlogSearchResults'
import BlogPostsByIndustry from '../components/BlogPostsByIndustry'
import BlogListHeader from '../components/BlogListHeader'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '64px 0px 50px',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      padding: '64px 0px 50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '28px 0px',
    },
  },
  section: {
    maxWidth: 1200,
    margin: '0 auto',
    padding: '0px 64px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 32px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px',
    },
  },
  divider: {
    margin: '48px 0px',
  },
  darkSection: {
    padding: '64px 0px',
    backgroundColor: 'rgba(33, 33, 33)',
  },
}))

const Home = (props) => {
  const {
    posts,
    tags,
    query,
    filterTag,
    onSearchChange,
    onTagChange,
  } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <BlogListHeader
          searchQuery={query}
          onSearchChange={onSearchChange}
          tags={tags}
          filterTag={filterTag}
          onTagChange={onTagChange}
        />
      </div>
      {
        (query.length >= 2) || filterTag
          ? (
            <div className={classes.section}>
              <BlogSearchResults posts={posts} searchQuery={query} filterTag={filterTag} />
            </div>
          )
          : (
            <>
              <div className={classes.darkSection}>
                <div className={classes.section}>
                  <BlogHighlights posts={posts} />
                </div>
              </div>
              <div className={classes.section}>
                <BlogPostsByIndustry posts={posts} />
              </div>
            </>
          )
      }
    </div>
  )
}

Home.propTypes = {}
Home.defaultProps = {}

export default Home
