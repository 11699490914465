import React, { useReducer, useState, memo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'
import questions from './questions'
import { emailApi } from '../../utils/api'

const useStyles = makeStyles({
  root: {
    marginTop: 30,
    marginBottom: 16,
  },
  otherField: {
    flex: 1,
  },
  submitButton: {
    marginTop: 30,
    textAlign: 'center',
  },
  successMsg: {
    marginTop: 50,
    marginBottom: 50,
    textAlign: 'center',
  },
})

const Question = memo((props) => {
  const classes = useStyles()
  const { value, option, multiple, questionKey, onMultiSelect, onSelect, onBlurOther } = props

  return (
    <Grid container>
      <Grid item>
        <FormControlLabel
          label={option}
          control={(
            <Checkbox
              checked={
                multiple
                  ? value[option]
                  : value === option
              }
              name={option}
              onChange={(e) => {
                if (multiple) {
                  onMultiSelect(questionKey, e)
                } else {
                  onSelect(questionKey, e)
                }
              }}
            />
          )}
        />
      </Grid>
      {
        option === 'Other:' && (
          <Grid item classes={{ item: classes.otherField }}>
            <TextField
              fullWidth
              name={questionKey}
              onBlur={onBlurOther}
              margin="dense"
            />
          </Grid>
        )
      }
    </Grid>
  )
})

const initialValues = questions.reduce((acc, cur) => {
  acc[cur.key] = ''
  if (cur.multiple) {
    acc[cur.key] = cur.options.reduce((a, c) => {
      a[c] = false

      return a
    }, {})
  }

  return acc
}, { otherValues: {} })

const getInitValues = (initState) => initState

const valueReducer = (state, action) => {
  if (action.reset) return getInitValues(action.payload)
  return {
    ...state,
    [action.name]: action.value,
  }
}

const errorReducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return action.errors
    default:
      return {
        ...state,
        [action.name]: false,
      }
  }
}


const Questionnaire = (props) => {
  const { onClose } = props
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [submitStatus, setSubmitStatus] = useState('')
  const [values, dispatchValues] = useReducer(
    valueReducer,
    initialValues,
    getInitValues,
  )
  // error message = 'This is a required question'
  const [errors, dispatchErrors] = useReducer(
    errorReducer,
    {},
  )

  const onBlur = (e) => {
    const { value, name } = e.target

    dispatchErrors({ name })
    dispatchValues({ name, value })
  }

  const onSelect = (key, e) => {
    const { name } = e.target

    dispatchErrors({ name: key })
    dispatchValues({ name: key, value: values[key] === name ? '' : name })
  }

  const onMultiSelect = (key, e) => {
    const { name } = e.target
    const vals = values[key]
    vals[name] = !vals[name]

    dispatchErrors({ name: key })
    dispatchValues({ name: key, value: vals })
  }

  const onBlurOther = (e) => {
    const { value, name } = e.target

    const vals = values.otherValues
    vals[name] = value

    dispatchValues({ name: 'otherValues', value: vals })
  }

  const onValidate = () => {
    const emailRegex = /\S+@\S+\.\S+/
    const errorMsg = 'This is a required field.'
    const emailErrorMsg = 'This is not a valid email.'

    let hasError = false
    let firstErrorKey = null
    const errorObject = {}

    questions.forEach((q) => {
      let isInvalid = false

      if (q.required) {
        if (q.multiple) {
          // ensure at least option is selected
          // might be a little bit more performant to check for some true instead of all false
          isInvalid = Object.values(values[q.key]).every((sel) => !sel)
        } else {
          isInvalid = values[q.key] === ''
        }
      }

      if (q.key === 'email' && values.email) {
        isInvalid = !(new RegExp(emailRegex).test(values[q.key]))
      }

      if (isInvalid) {
        firstErrorKey = firstErrorKey || q.key
        hasError = true
        errorObject[q.key] = q.key === 'email' ? emailErrorMsg : errorMsg
      }
    })

    return {
      hasError,
      firstErrorKey,
      errorObject,
    }
  }

  const createPayload = () => {
    // create the request body in this form:
    /**
     * {
        “email”: “benny.wang@staccc.com”,
        “firstName”: “Benny”,
        “lastName”: “Wang”,
        “description”: “Trying RTV new email”,
        “questions”: [{
          “label”: “Question 1”,
          “response”: “Response 1”
        }, {
          “label”: “Question 2”,
          “response”: “Response 2”
        }, {
          “label”: “Question 3”,
          “response”: “Response 3”
        }]
      }
     */
    const getOtherValue = (qKey) => {
      return values.otherValues[qKey]
    }

    const payload = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      questions: questions.map(x => {
        const res = {
          label: x.question,
          response: '',
        }

        if (x.multiple) {
          const selected = []
          x.options.forEach((o) => {
            const val = values[x.key][o]
            if (val) {
              selected.push(
                o === 'Other:'
                  ? `Other: ${getOtherValue(x.key)}`
                  : o,
              )
            }
          })

          res.response = selected.join(', ')
        } else {
          res.response = values[x.key]
        }

        return res
      }),
    }

    return payload
  }

  const onSubmit = async () => {
    const { hasError, firstErrorKey, errorObject } = onValidate()

    if (hasError) {
      dispatchErrors({
        type: 'set',
        errors: errorObject,
      })

      document.querySelector(`#${firstErrorKey}`).scrollIntoView({
        // these options are experimental and not fully supported by
        // all major browsers
        behavior: 'smooth',
        block: 'center',
      })

      return
    }

    setLoading(true)

    try {
      const payload = createPayload()

      const res = await emailApi('/rtv/join', {
        method: 'POST',
        body: payload,
      })

      if (res.ok) {
        setSubmitStatus('success')
        dispatchValues({ reset: true, payload: initialValues })
      } else {
        throw new Error('error')
      }
    } catch (err) {
      console.log(err)
      setSubmitStatus('error')
    }

    setLoading(false)
  }

  const renderQuestionComponent = (data) => {
    if (data.options) {
      return (
        <FormControl error={Boolean(errors[data.key])} component="fieldset">
          <FormGroup>
            <Grid container direction="column" spacing={matchesMobile ? 2 : 0}>
              {
                data.options.map((option) => (
                  <Grid key={option} item xs={12}>
                    <Question
                      questionKey={data.key}
                      multiple={data.multiple}
                      value={values[data.key]}
                      option={option}
                      onSelect={onSelect}
                      onMultiSelect={onMultiSelect}
                      onBlurOther={onBlurOther}
                    />
                  </Grid>
                ))
              }
            </Grid>
          </FormGroup>
          {Boolean(errors[data.key]) && <FormHelperText>{errors[data.key]}</FormHelperText>}
        </FormControl>
      )
    }

    return (
      <TextField
        fullWidth
        name={data.key}
        onBlur={onBlur}
        error={Boolean(errors[data.key])}
        helperText={errors[data.key] || ''}
        placeholder="Your answer"
      />
    )
  }

  if (submitStatus === 'success') {
    return (
      <>
        <div className={classes.successMsg}>
          <Typography variant="h3" gutterBottom>
            {"You're all set to go!"}
          </Typography>
          <Typography component="p">
            {"If you are not already a member of our Slack channel, we'll be contacting you shortly with an invite to join."}
          </Typography>
        </div>
        <Grid container justify="center">
          <Button variant="contained" size="large" color="secondary" onClick={onClose}>
            Close
          </Button>
        </Grid>
      </>
    )
  }

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.root}
        spacing={8}
      >
        {
          questions.map((q) => {
            return (
              <Grid
                key={q.key}
                container
                item
                xs={12}
                sm={10}
                spacing={1}
              >
                <Grid item xs={12}>
                  <span id={q.key} />
                  <Typography>
                    {q.question}
                    {
                      q.required && (
                        <Typography component="span" color="secondary">
                          &nbsp;*
                        </Typography>
                      )
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {renderQuestionComponent(q)}
                </Grid>
              </Grid>
            )
          })
        }
      </Grid>
      <div className={classes.submitButton}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          disabled={loading}
          onClick={onSubmit}
        >
          Submit
        </Button>
      </div>
      {
        submitStatus === 'error' && (
          <Typography component="p" align="center" variant="error" color="error">
            There was an error while submitting. Please try again.
          </Typography>
        )
      }
    </div>
  )
}

Questionnaire.propTypes = {}
Questionnaire.defaultProps = {}

export default Questionnaire
