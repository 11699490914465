import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import SubscriptionTextField from 'components/SubscriptionTextField'
import SocialMediaBar from 'components/SocialMediaBar'
import GlobalSearch from 'components/GlobalSearch'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Fade from '@material-ui/core/Fade'
import Drawer from '@material-ui/core/Drawer'
import MenuIcon from '@material-ui/icons/Menu'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DarkLogo from 'assets/images/darkLogo.png'
import LightLogo from 'assets/images/logo.png'

import { MOBILE_HEADER_HEIGHT, colors } from 'theme'

import MenuLinks from './MenuLinks'

const useStyles = makeStyles({
  fixed: {
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 1000,
  },
  header: {
    backgroundColor: '#f9f9f9',
    position: 'absolute',
    padding: '0px 20px',
    height: MOBILE_HEADER_HEIGHT,
    width: '100%',
    top: 0,
    left: 0,
  },
  openHeader: {
    backgroundColor: 'transparent',
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100%',
    backgroundColor: colors.black.primary,
    position: 'relative',
    padding: '141px 40px 40px',
  },
  linkCntr: {
    margin: '48px 0px',
  },
  socialIcons: {
    marginLeft: -12,
  },
  globalSearch: {
    marginTop: 'auto',
  },
})

const MobileAppBar = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  return (
    <div className={classes.fixed}>
      <Grid classes={{ container: classes.header }} container alignItems="center" justify="space-between">
        <Grid item>
          <Link to="/" onClick={() => { setOpen(false) }}>
            <img src={DarkLogo} alt="Red Thread Ventures" width={85} />
          </Link>
        </Grid>
        <Grid item>
          <IconButton onClick={() => { setOpen(!open) }} size="small">
            <MenuIcon fontSize="large" color="secondary" />
          </IconButton>
        </Grid>
      </Grid>
      <Drawer
        open={open}
        anchor="right"
        onClose={() => { setOpen(false) }}
      >
        <div className={classes.drawerContent}>
          <Fade in={open} style={{ transitionDelay: '225ms' }}>
            <Grid classes={{ container: clsx(classes.header, classes.openHeader) }} container alignItems="center" justify="space-between">
              <Grid item>
                <Link to="/" onClick={() => { setOpen(false) }}>
                  <img src={LightLogo} alt="Red Thread Ventures" width={85} />
                </Link>
              </Grid>
              <Grid item>
                <IconButton onClick={() => { setOpen(!open) }} size="small">
                  <ArrowForwardIcon fontSize="large" color="secondary" />
                </IconButton>
              </Grid>
            </Grid>
          </Fade>
          <MenuLinks onClick={() => { setOpen(false) }} />
          <div className={classes.linkCntr}>
            <SubscriptionTextField
              theme="white"
            />
          </div>
          <div className={classes.socialIcons}>
            <SocialMediaBar theme="black" follow orientation="horizontal" />
          </div>
          <div className={classes.globalSearch}>
            <GlobalSearch />
          </div>
        </div>
      </Drawer>
    </div>
  )
}

MobileAppBar.propTypes = {}
MobileAppBar.defaultProps = {}

export default MobileAppBar
