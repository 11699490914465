import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Pagination from '@material-ui/lab/Pagination'
import Typography from 'components/Typography'
import jump from 'jump.js'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'

import BlogPostMinimized from './BlogPostMinimized'

const useStyles = makeStyles((theme) => ({
  header: {
    opacity: 0.5,
    margin: '40px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: '40px 0px 20px',
    },
  },
  postList: {
    marginBottom: 32,
  },
  chipGroup: {
    marginBottom: 32,
  },
  chip: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
  chipIcon: {
    opacity: 0.3,
  },
  selectedChipIcon: {
    color: theme.palette.secondary.main,
  },
}))

const POSTS_PER_PAGE = 5

const INDUSTRIES_LIST = [
  'Auto',
  'Biotech',
  'Blockchain',
  'Cleantech',
  'Crypto',
  'Delivery',
  'E-Commerce',
  'E-Sports',
  'Fintech',
  'Gaming',
  'Healthtech',
  'Impact',
]

const BlogPostsByIndustry = (props) => {
  const { posts } = props
  const [page, setPage] = useState(1)
  const [selectedIndustries, setSelectedIndustries] = useState(INDUSTRIES_LIST.reduce((acc, cur) => ({ ...acc, [cur]: false }), {}))
  const classes = useStyles()
  const matchesTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const isIndustrySelected = useMemo(() => (
    INDUSTRIES_LIST.some(x => selectedIndustries[x])
  ), [selectedIndustries])

  const filteredPosts = useMemo(() => (
    posts.filter(x => x.industry.some(x => selectedIndustries[x]))
  ), [posts, selectedIndustries])

  const selectedPosts = isIndustrySelected ? filteredPosts : posts

  const postsOnCurrentPage = selectedPosts.slice((page - 1) * POSTS_PER_PAGE, page * POSTS_PER_PAGE)
  const pages = Math.ceil(selectedPosts.length / POSTS_PER_PAGE)

  const onChange = (e, nextPage) => {
    setPage(nextPage)
    jump('#industry-posts')
  }

  const onClickIndustry = (industry) => {
    setSelectedIndustries({
      ...selectedIndustries,
      [industry]: !selectedIndustries[industry],
    })
  }

  return (
    <div id="industry-posts">
      <Typography className={classes.header} weight="light" variant={matchesTablet ? 'h3' : 'h2'}>Industries</Typography>
      <Grid
        classes={{ container: classes.chipGroup }}
        container
        spacing={1}
        wrap="wrap"
      >
        {
          INDUSTRIES_LIST.map(x => (
            <Grid key={x} item xs={6} sm="auto">
              <Chip
                icon={selectedIndustries[x] ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                label={x}
                onClick={() => { onClickIndustry(x) }}
                classes={{ root: classes.chip, icon: selectedIndustries[x] ? classes.selectedChipIcon : classes.chipIcon }}
                variant="outlined"
              />
            </Grid>
          ))
        }
      </Grid>
      {
        postsOnCurrentPage.length
          ? (
            <>
              <div className={classes.postList}>
                {
                  postsOnCurrentPage.map((x, i) => (
                    <div className={classes.post}>
                      <BlogPostMinimized key={x.id} post={x} tabIndex={i + 1} />
                    </div>
                  ))
                }
              </div>
              <Grid container justify="center">
                <Pagination
                  count={pages}
                  page={page}
                  shape="rounded"
                  size="large"
                  color="secondary"
                  onChange={onChange}
                />
              </Grid>
            </>
          )
          : <Typography weight="light">There aren&apos;t any posts right now!</Typography>
      }
    </div>
  )
}

BlogPostsByIndustry.propTypes = {}
BlogPostsByIndustry.defaultProps = {}

export default BlogPostsByIndustry
