import React from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Card from 'components/Card'
import CardHover from 'components/Card/CardHover'
import Image from 'components/Image'
import ContainerBadge from 'components/ContainerBadge'
import createPostURL from 'utils/createPostURL'
import BlogIndustryList from './BlogIndustryList'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 0,
    marginBottom: 96,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginBottom: 40,
    },
  },
  content: {
    marginLeft: 0,
  },
  postDate: {
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      opacity: 1.0,
    },
  },
}))

const BlogListItem = (props) => {
  const { post, tabIndex } = props
  const classes = useStyles()
  const matchesTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const to = createPostURL(post.urlPermalink)

  return (
    <CardHover disabled={matchesTablet}>
      <Card
        className={classes.card}
        to={to}
        tabIndex={tabIndex}
      >
        <div className={classes.content}>
          <ContainerBadge
            fullWidth
            text={post.title}
            description={format(new Date(post.createdAt), 'MMM dd, yyyy')}
            additionalInfo={(
              <BlogIndustryList industry={post.industry} />
            )}
          >
            <Image src={post.heroImage.file.url} height={matchesTablet ? 300 : 500} alt={post.title} />
          </ContainerBadge>
        </div>
      </Card>
    </CardHover>
  )
}

BlogListItem.propTypes = {}
BlogListItem.defaultProps = {}

export default BlogListItem
