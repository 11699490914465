export default [
  {
    question: 'First Name',
    required: true,
    key: 'firstName',
  },
  {
    question: 'Last Name',
    required: true,
    key: 'lastName',
  },
  {
    question: 'Email Address',
    required: true,
    key: 'email',
  },
  {
    question: 'Where are you based out of?',
    required: false,
    key: 'location',
  },
  {
    question: 'How would you characterize your role?',
    required: true,
    multiple: false,
    options: [
      'Angel Investor',
      'Family Office',
      'Venture Capital',
      'Institutional Investor',
      'Investment Banker',
      'Other:',
    ],
    key: 'role',
  },
  {
    question: 'LinkedIn URL',
    required: false,
    key: 'linkedIn',
  },
  {
    question: 'How did you hear about RTV?',
    required: true,
    multiple: false,
    options: [
      'Existing affiliate',
      'Networking events',
      'Website',
      'Social media(LinkedIn, Facebook, Instagram, Twitter, etc.)',
      'Email campaign',
      'Surfing the internet',
      'Other:',
    ],
    key: 'heardFrom',
  },
  {
    question: 'If you were referred to RTV by an existing affiliate, please enter their full name',
    required: false,
    key: 'referredBy',
  },
  {
    question: 'What brings you to RTV? I am interested in... (select all that apply)',
    required: true,
    multiple: true,
    options: [
      'Checking things out to start',
      'Connecting with like - minded investors and advisors',
      'Sharing investment ideas and interests',
      'Collaborating on investment opportunities',
      'Assisting with due diligence and working with investee companies',
      'Investment events, networks and social activities',
      'Other:',
    ],
    key: 'reason',
  },
  {
    question: 'How would you describe your private equity investment interests? (select all that apply)',
    required: true,
    multiple: true,
    options: [
      'Just getting started',
      'Experienced but passive investor',
      'Experienced',
      'Interested in going public investment opportunities',
      'Connecting investors with RTV',
      'Other:',
    ],
    key: 'interests',
  },
  {
    question: 'I am a(n)...',
    required: true,
    multiple: false,
    options: [
      'Analyst',
      'Investor',
      'Director',
      'CFO',
      'Angel',
      'Other:',
    ],
    key: 'position',
  },
  {
    question: 'Working in a(n)...',
    required: true,
    multiple: false,
    options: [
      'Advisory Firm',
      'Investment Firm',
      'Brokerage',
      'Solo Investor',
      'Other:',
    ],
    key: 'workingAt',
  },
  {
    question: 'Enter company name',
    required: false,
    key: 'company',
  },
  {
    question: "My company's industry/my expertise is... (select all that apply)",
    required: true,
    multiple: true,
    options: [
      'Adtech',
      'Agtech',
      'Apparel',
      'Artificial Intelligence (AI) and Machine Learning',
      'Audiotech',
      'Augmented Reality (AR)',
      'Automotive',
      'Beauty',
      'Big Data',
      'Biotech',
      'Blockchain',
      'Business Automotive',
      'Cannabis',
      'Cleantech',
      'Cloudtech',
      'Construction tech',
      'CPG',
      'Crypto',
      'Cybersecurity',
      'Digital tech',
      'Ecommerce',
      'Edtech',
      'E-sports',
      'Femtech',
      'Fintech',
      'Foodtech',
      'Gaming',
      'Healthtech',
      'HRtech',
      'Impact',
      'Infrastructure',
      'Legaltech',
      'Marketing tech',
      'Real estate tech',
      'SAAS',
      'Virtual Reality (VR)',
      'Other:',
    ],
    key: 'industryOrExpertise',
  },
  {
    question: 'Choose at least one location that interests you:',
    required: true,
    multiple: true,
    options: [
      'Vancouver',
      'Toronto',
      'Montreal',
      'Calgary',
      'New York',
      'San Francisco',
      'Seattle',
      'London',
      'Los Angeles',
      'Tokyo',
      'Chicago',
      'United States',
      'United Kingdom',
      'Canada',
      'China',
      'India',
      'Australia',
      'France',
      'Spain',
      'Russia',
      'Asia Pacific',
      'European Union',
      'Africa',
      'North America',
      'South America',
      'Other:',
    ],
    key: 'locationOfInterest',
  },
  {
    question: 'Choose at least one industry that interests you (select all that apply)',
    required: true,
    multiple: true,
    options: [
      'Adtech',
      'Agtech',
      'Apparel',
      'Artificial Intelligence (AI) and Machine Learning',
      'Audiotech',
      'Augmented Reality (AR)',
      'Automotive',
      'Beauty',
      'Big Data',
      'Biotech',
      'Blockchain',
      'Business Automotive',
      'Cannabis',
      'Cleantech',
      'Cloudtech',
      'Construction tech',
      'CPG',
      'Crypto',
      'Cybersecurity',
      'Digital tech',
      'Ecommerce',
      'Edtech',
      'E-sports',
      'Femtech',
      'Fintech',
      'Foodtech',
      'Gaming',
      'Healthtech',
      'HRtech',
      'Impact',
      'Infrastructure',
      'Legaltech',
      'Marketing tech',
      'Real estate tech',
      'SAAS',
      'Virtual Reality (VR)',
      'Other:',
    ],
    key: 'industryOfInterest',
  },
  {
    question: 'What is your typical investment size?',
    required: true,
    multiple: false,
    otherAnswer: false,
    options: [
      '0-100k',
      '100k-500k',
      '500k-1M',
      '1M+',
    ],
    key: 'investmentSize',
  },
  {
    question: 'What is the typical size of the deals you invest in?',
    required: true,
    otherAnswer: false,
    options: [
      '0-500k',
      '500k-2M',
      '2M-10M',
      '10M-50M',
      '50M+',
    ],
    key: 'dealSize',
  },
  {
    question: 'What is your go-to podcast for entertainment?',
    required: true,
    key: 'podcast',
  },
  {
    question: "As RTV is a global network so we're curious... if you could travel anywhere in the world, where would you go?",
    required: true,
    key: 'travel',
  },
  {
    question: 'Select the communications you are interested in staying updated with (select all that apply)',
    required: false,
    multiple: true,
    otherAnswer: false,
    options: [
      'Monthly updates',
      'Weekly newsletter',
      'Monthly investor communications',
    ],
    key: 'communications',
  },
]
