import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AnimatedText from 'components/AnimatedText'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TypeComponent from 'components/Typography'
import Button from 'components/Button'
import SubscriptionPopup from 'components/SubscriptionPopup'
import AnimatedNode from 'components/AnimatedNode'
import Visible from 'components/Visible'
import Carousel from '@brainhubeu/react-carousel'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import Close from '@material-ui/icons/Close'
import jump from 'jump.js'

import MobileBackgroundImage from 'assets/images/landing-background.jpeg'
import ArrowImage from 'assets/images/investor_arrow.png'
import ArrowWhiteImage from 'assets/images/investor_arrow_white.png'
import ArrowDownImage from 'assets/images/investor_arrow_down.png'
import ArrowDownWhiteImage from 'assets/images/investor_arrow_down_white.png'
import PointImage from 'assets/images/investor_point.svg'
import SlackImage from 'assets/images/investor_slack.png'
import OnlinePitchImage from 'assets/images/investor_online_pitch.png'
import ConnectedImage from 'assets/images/investor_connected.png'
import MemberImage from 'assets/images/investor_member.png'
import MobileBackgroundCoverImage from 'assets/images/contact_mobile_background_cover.png'
import { MOBILE_HEADER_HEIGHT, colors } from 'theme'
import Questionnaire from './Questionnaire'

import { emailApi } from '../utils/api'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
  },
  landingContainer: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      height: '100%',
    }
  },
  container_1: {
    width: '100%',
    padding: '160px 130px 0px 100px',
    zIndex: 10,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: '0 auto',
    },
  },
  container_2: {
    marginTop: 20,
  },
  container_3: {
    width: '100%',
    position: 'relative',
    padding: '30px 100px 88px 100px',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 88px)',
      padding: '88px 40px 40px 40px',
    }
  },
  headerText: {
    fontSize: 46,
    lineHeight: 1.22,
    fontVariationSettings: '"wght" 700',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: 1.5,
      color: 'white'
    }
  },
  subheaderText: {
    fontSize: 24,
    color: '#212121',
  },
  slackButton: {
    height: 57,
    minWidth: 278,
    borderRadius: 30,
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      borderColor: 'white',
      color: 'white',
      height: 48,
      minWidth: 235,
      fontSize: 16,
    },
  },
  imageBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionContainer: {
    marginTop: 88,
  },
  sectionHeader: {
    color: '#212121',
    fontSize: 36,
    fontVariationSettings: '"wght" 700',
    marginBottom: 88,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: 1.5,
      marginBottom: 40,
    }
  },
  sectionHeader_2: {
    color: '#212121',
    fontSize: 36,
    fontVariationSettings: '"wght" 700',
    marginBottom: 47,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: 1.5,
      marginBottom: 40,
    }
  },
  sectionText: {
    textAlign: 'center',
    maxWidth: 220,
    margin: 'auto',
    wordWrap: 'break-word',
  },
  separator: {
    height: 1,
    background: '#d9d9d9',
    width: '100%',
    marginBottom: 60,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    }
  },
  pointText: {
    margin: 'auto 0',
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    }
  },
  bottomText: {
    color: '#d9d9d9',
    fontSize: 20,
    marginTop: 68,
    [theme.breakpoints.down('sm')]: {
      marginTop: 28,
      fontSize: 12,
    },
  },
  mobileLanding: {
    height: '100vh',
    width: 'auto',
    marginTop: -88,
    backgroundImage: `url(${MobileBackgroundImage})`,
    backgroundSize: 'cover',
    position: 'relative',
    padding: '160px 0px 130px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: '288px 20px 40px 20px',
      alignItems: 'start',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  backgroundCover: {
    backgroundImage: `url(${MobileBackgroundCoverImage})`,
    backgroundSize: 'cover',
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0.5,
    zIndex: 1,
  },
  subscriptionAgreement: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      color: 'white',
    },
  },
  carouselBox: {
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  carouselItem: {
    height: '100%',
  },
  carouselDotsBox: {
    margin: (3 * 8) / 2, // needs to match 1/2 of the child's calculated Grid.spacing
    marginBottom: 0,
  },
  carouselDot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: colors.red.primary,
    opacity: 1,
    transition: 'opacity 250ms ease-in-out',
  },
  activeCarouselDot: {
    opacity: 0.2,
  },
  pointBox: {
    display: 'flex',
  },
  alignCenter: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  seeMoreBox: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: 30,
    bottom: 30,
    zIndex: 10,
    cursor: 'pointer',
  },
  seeMoreText: {
    color: colors.red.primary,
    fontSize: 14,
    marginLeft: 20,
  },
  white: {
    color: 'white',
  },
  align: {
    alignItems: 'flex-start',
    marginTop: 2,
  },
  signupCard: {
    padding: '40px 80px',
    [theme.breakpoints.down('md')]: {
      padding: '40px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px',
    },
  },
  topAccent: {
    background: 'linear-gradient(102deg, #ff5858 -4%, rgba(255, 255, 255, 0) 50%)',
    width: '100%',
    height: 10,
    marginBottom: -10,
  },
  description: {
    paddingTop: 12,
  },
  signupRoot: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  signupHeader: {
    padding: '20px 40px 0px',
  },
  submitButton: {
    textAlign: 'center',
    marginTop: 30,
  },
}))

const SlideWhenVisible = ({ children }) => (
  <Visible once>
    {
      ({ isVisible }) => (
        <AnimatedNode
          show={isVisible}
          direction="down"
        >
          {children}
        </AnimatedNode>
      )
    }
  </Visible>
)

const sectionData = [
  { image: MemberImage, title: 'Slack Channel', text: 'Join our "members-only" Slack channel' },
  { image: ConnectedImage, title: 'Connected Investments', text: 'You can introduce "connected investments", like-minded entrepreneurs, advisors, and investors to the network' },
  { image: OnlinePitchImage, title: 'Investor Roundtables', text: 'Participate in Investor Roundtables on Zoom.us' },
]

const involvedData = [
  { title: 'Deals and Resources', text: 'You have secure access to deal rooms and due diligence resources' },
  { title: 'Inclusive Community', text: 'We are an inclusive community, members are encouraged to introduce like-minded individuals to the network' },
  { title: 'Investor Network', text: 'Your Net Asset Value tracking and tax fillings are maintained by RTV for each respective tax jurisdiction' },
]

const Contact = (props) => {
  const classes = useStyles()
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [slide, setSlide] = useState(0)
  const [isJoinOpen, setJoinOpen] = useState(false)

  const Section = ({ image, text }) => (
    <Grid container spacing={4}>
      <Grid item xs={12} className={classes.imageBox}>
        <img src={image} width={75} height={85} alt="section" />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.sectionText}>{text}</Typography>
      </Grid>
    </Grid>
  )

  const NUM_SECTIONS = 3
  const MemberSection = <Section {...sectionData[0]} />
  const ConnectedSection = <Section {...sectionData[1]} />
  const OnlinePitchSection = <Section {...sectionData[2]} />

  const Point = ({ text }) => (
    <SlideWhenVisible>
      <Grid container spacing={1}>
        <Grid item xs={matchesMobile ? 2 : 1} className={clsx(classes.imageBox, classes.align)}>
          <img src={PointImage} width={matchesMobile ? 14 : 30} height={matchesMobile ? 14 : 30} alt="section" />
        </Grid>
        <Grid item xs={matchesMobile ? 10 : 11} className={classes.pointText}>
          <Typography>{text}</Typography>
        </Grid>
      </Grid>
    </SlideWhenVisible>
  )

  const jumpPage = (next) => {
    jump(next, {
      duration: 1000,
      offset: -88,
    })
  }

  const SeeMore = ({ text, white, next }) => (
    <div className={classes.seeMoreBox} onClick={() => jumpPage(next)}>
      <img src={white ? ArrowDownWhiteImage : ArrowDownImage} width={24} height={24} alt="see-more-arrow" />
      <Typography className={clsx(classes.seeMoreText, white && classes.white)}>See more</Typography>
    </div>
  )

  const Responsive = ({ children }) => (
    matchesMobile ?
      <div className={classes.mobileLanding}>
        <div className={classes.backgroundCover} />
        {children}
        <SeeMore white next="#section-how" />
      </div> :
      <div className={classes.landingContainer}>{children}</div>
  )

  return (
      <div className={classes.root}>
        <Responsive>
          <Grid container spacing={3} className={classes.container_1}>
            <Grid item xs={12} className={classes.alignCenter}>
              <AnimatedText className={classes.headerText}>Start now by joining us in our Slack channel.</AnimatedText>
            </Grid>
            <Grid item xs={12} className={classes.alignCenter}>
              <Button variant="secondary" className={classes.slackButton} onClick={() => { setJoinOpen(true) }}>Join Network</Button>
            </Grid>
          </Grid>
          {
            matchesMobile ? <></> : (
              <Grid container className={classes.container_2} justify="flex-end">
                <Grid item xs={12}>
                  <img src={SlackImage} width="100%" height="auto" alt="image-location-pin" />
                </Grid>
              </Grid>
            )
          }
        </Responsive>
        <Grid id="section-how" container className={classes.container_3}>
          <Grid item xs={12}>
            <SlideWhenVisible>
              <Typography className={classes.sectionHeader}>How do you get involved?</Typography>
            </SlideWhenVisible>
          </Grid>
          {
            matchesMobile ? (
              <Grid item xs={12}>
                <Carousel
                  value={slide}
                  onChange={(value) => { setSlide(value % NUM_SECTIONS) }}
                  keepDirectionWhenDragging
                  infinite
                >
                  <div className={classes.carouselItem}>{MemberSection}</div>
                  <div className={classes.carouselItem}>{ConnectedSection}</div>
                  <div className={classes.carouselItem}>{OnlinePitchSection}</div>
                </Carousel>
                <div className={classes.carouselDotsBox}>
                  <Grid container justify="center">
                    {
                      new Array(NUM_SECTIONS).fill(null).map((_, i) => (
                        <Grid key={`dot-${i}`} item>
                          <IconButton onClick={() => { setSlide(i) }}>
                            <div
                              className={clsx(
                                classes.carouselDot,
                                { [classes.activeCarouselDot]: slide === i },
                              )}
                            />
                          </IconButton>
                        </Grid>
                      ))
                    }
                  </Grid>
                </div>
              </Grid>
            ) : (
              <>
                <Grid item xs={4}>
                  <SlideWhenVisible>
                    {MemberSection}
                  </SlideWhenVisible>
                </Grid>
                <Grid item xs={4}>
                  <SlideWhenVisible>
                    {ConnectedSection}
                  </SlideWhenVisible>
                </Grid>
                <Grid item xs={4}>
                  <SlideWhenVisible>
                    {OnlinePitchSection}
                  </SlideWhenVisible>
                </Grid>
              </>
            )
          }
          { matchesMobile && <SeeMore next="#section-involved"/> }
        </Grid>
        <div className={classes.separator} />
        <Grid id="section-involved" container spacing={2} className={classes.container_3}>
          <Grid item xs={12}>
            <SlideWhenVisible>
              <Typography className={classes.sectionHeader_2}>Once you're involved,</Typography>
            </SlideWhenVisible>
          </Grid>
          <Grid item xs={12}>
            <Point text={involvedData[0].text} />
          </Grid>
          <Grid item xs={12}>
            <Point text={involvedData[1].text} />
          </Grid>
          <Grid item xs={12}>
            <Point text={involvedData[2].text} />
          </Grid>
          <Grid item xs={matchesMobile ? 2 : 1} />
          <Grid item xs={matchesMobile ? 10 : 11}>
            <SlideWhenVisible>
              <TypeComponent className={classes.bottomText} weight="light">Other Member Benefits are coming...</TypeComponent>
            </SlideWhenVisible>
          </Grid>
        </Grid>
        <Dialog
          open={isJoinOpen}
          onClose={() => { setJoinOpen(false) }}
          maxWidth="md"
          fullWidth
          fullScreen={matchesMobile}
          disableEscapeKeyDown
          disableBackdropClick
        >
          <div className={classes.signupRoot}>
            <IconButton onClick={() => { setJoinOpen(false) }} className={classes.closeButton}>
              <Close />
            </IconButton>
            <div className={classes.topAccent} />
            <TypeComponent className={classes.signupHeader} color="secondary" weight="light">Onboarding Questionnaire</TypeComponent>
            <div className={classes.signupCard}>
              <TypeComponent gutterBottom>
                Welcome to Red Thread Ventures (RTV), we are excited to have you join our extensive community of investors and entrepreneurs. Before we get started, we would love to learn more about you!
              </TypeComponent>
              <TypeComponent variant="body2" color="secondary">
              *Required
              </TypeComponent>
              <Questionnaire onClose={() => { setJoinOpen(false) }} />
            </div>
          </div>
        </Dialog>
        <SubscriptionPopup />
      </div>
  )
}

Contact.propTypes = {}
Contact.defaultProps = {}

export const dataForGlobalSearch = () => {
  const data = []

  sectionData.forEach((x) => {
    data.push({
      title: x.title,
      content: x.text,
      path: '/investor-network',
    })
  })

  involvedData.forEach((x) => {
    data.push({
      title: x.title,
      content: x.text,
      path: '/investor-network',
    })
  })

  data.push({
    title: 'Investor Network',
    content: 'Start now by joining us in our Slack channel.',
    path: '/investor-network',
  })

  return data
}
export default Contact
