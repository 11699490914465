import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import fetchPodcasts from './utils'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
    margin: '0px auto',
    minHeight: '100vh',
    padding: '90px 90px 50px',
    [theme.breakpoints.down('md')]: {
      padding: '50px 50px 50px',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 'unset',
      padding: '50px 20px 50px',
    },
  },
  podcast: {
    marginTop: 50,
  },
  podcastItem: {
    '& iframe': {
      '@media (max-width: 425px)': {
        width: '300px',
      },
    },
  },
}))

const Podcast = (props) => {
  const classes = useStyles()
  const [podcasts, setPodcasts] = useState([])

  useEffect(() => {
    const getPodcasts = async () => {
      try {
        const res = await fetchPodcasts()

        setPodcasts(res)
      } catch (err) {
        console.log(err)
      }
    }

    getPodcasts()
  }, [])

  return (
    <div className={classes.root}>
      <Typography variant="h2" color="secondary"><b>Podcasts</b></Typography>
      <div className={classes.podcast}>
        <Grid container direction="column" spacing={4}>
          {
            podcasts.map((podcast) => (
              <Grid
                container
                item
                key={podcast.title}
                // classes={{ item: clsx({ [classes.podcastItem]: podcasts.length }) }}
              >
                {podcast.parsedBody}
              </Grid>
            ))
          }
        </Grid>
      </div>
    </div>
  )
}

Podcast.propTypes = {}
Podcast.defaultProps = {}

export default Podcast
