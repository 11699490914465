import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MuiTypography from '@material-ui/core/Typography'

const useWeightStyles = makeStyles({
  light: {
    fontVariationSettings: '"wght" 300',
  },
  normal: {
    fontVariationSettings: '"wght" 400',
  },
  bold: {
    fontVariationSettings: '"wght" 700',
  },
})

const Typography = (props) => {
  const { className, weight, ...rest } = props
  const classes = useWeightStyles()

  return (
    <MuiTypography className={clsx(classes[weight], className)} {...rest} />
  )
}

Typography.propTypes = {
  weight: PropTypes.oneOf([
    'light',
    'normal',
    'bold',
  ]),
}

Typography.defaultProps = {
  weight: 'normal',
}

export default Typography
