// used to display the blog title in a list
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'components/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '24px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: '16px 0px',
    },
  },
}))

const BlogDescription = (props) => {
  const { description } = props
  const classes = useStyles()

  return (
    <Typography className={classes.root} weight="light">{description}</Typography>
  )
}

BlogDescription.propTypes = {}
BlogDescription.defaultProps = {}

export default BlogDescription

