const theme = {}

export const colors = {
  black: {
    primary: '#212121',
  },
  red: {
    primary: '#ff5858',
  },
}

// determines sidebar width and also used to offset the sidebar
export const SIDEBAR_WIDTH = 320
export const MOBILE_HEADER_HEIGHT = 88

export default theme
