import React, { Fragment, useState } from 'react'
import Fuse from 'fuse.js'
import format from 'date-fns/format'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import createPostURL from 'utils/createPostURL'

import Typography from 'components/Typography'
import Card from 'components/Card'
import CardHover from 'components/Card/CardHover'
import BlogTitle from './BlogTitle'
import BlogTagList from './BlogTagList'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: 80,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 24,
    },
  },
  searchWrapper: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 12,
    },
  },
  headerText: {
    opacity: 0.5,
  },
  searchQuery: {
    opacity: 1.0,
    wordBreak: 'break-all',
  },
  card: {
    padding: '0px 20px 20px 80px',
    display: 'inline-block',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  createdAt: {
    fontWeight: 300,
    fontFamily: 'Pangram',
    opacity: 0.7,
  },
  itemFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    margin: '20px 0',
  },
}))

const BlogSearchResults = (props) => {
  const { searchQuery, filterTag, posts } = props
  const classes = useStyles()
  const matchesTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const filteredByTag = filterTag ? posts.filter(x => x.tags.includes(filterTag)) : posts

  const fuse = new Fuse(filteredByTag, {
    minMatchCharLength: 2,
    keys: ['title', 'tags'],
  })

  const filteredByQuery = fuse.search(searchQuery)

  const results = searchQuery ? filteredByQuery : filteredByTag.map(x => ({ item: x }))

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.searchWrapper}>
          <Typography className={classes.headerText} component="span" weight="light" variant={matchesTablet ? 'h3' : 'h2'}>Posts about </Typography>
          <Typography className={classes.searchQuery} component="span" variant={matchesTablet ? 'h3' : 'h2'} color="secondary">{'"'}{searchQuery}{'"'}</Typography>
        </div>
        <div>
          <Typography component="span" color="secondary" weight="light" variant="h3">{results.length} </Typography>
          <Typography className={classes.headerText} component="span" variant="h3" weight="light">in total</Typography>
        </div>
      </div>
      {
        results.map(({ item }, i) => (
          <Fragment key={item.id}>
            <CardHover disabled={matchesTablet}>
              <Card to={createPostURL(item.urlPermalink)} className={classes.card} tabIndex={i + 1}>
                <BlogTitle fontSize={matchesTablet ? 'small' : 'normal'} title={item.title} />
                <div className={classes.itemFooter}>
                  <BlogTagList tags={item.tags} />
                  <Typography variant={matchesTablet ? 'body1' : 'h3'} className={classes.createdAt}>{format(new Date(item.createdAt), 'MM/dd/yyyy')}</Typography>
                </div>
              </Card>
            </CardHover>
            <Divider className={classes.divider} />
          </Fragment>
        ))
      }
    </div>
  )
}

BlogSearchResults.propTypes = {}
BlogSearchResults.defaultProps = {}

export default BlogSearchResults
