import differenceInDays from 'date-fns/differenceInDays'

const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION'
const CHECK_LAST_POPUP_DATE = 'CHECK_LAST_POPUP_DATE'
const HIDE_SIDE_SOCIAL_BAR = 'HIDE_SIDE_SOCIAL_BAR'
const SET_SUBSCRIBED = 'SET_SUBSCRIBED'
const CHECK_LAST_SUB_DATE = 'CHECK_LAST_SUB_DATE'

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  keepSubscriptionClosed: true,
  hideSideSocialBar: false,
  isSubscribed: false,
}

// ------------------------------------
// Actions
// ------------------------------------

const closeSubscription = (bool) => ({
  type: SET_SUBSCRIPTION,
  bool,
})

const hideSideSocialBar = (bool) => ({
  type: HIDE_SIDE_SOCIAL_BAR,
  bool,
})

const checkLastPopupDate = () => ({
  type: CHECK_LAST_POPUP_DATE,
})

const setSubscribed = (bool) => ({
  type: SET_SUBSCRIBED,
  bool,
})

const checkLastSubDate = () => ({
  type: CHECK_LAST_SUB_DATE,
})

export const actions = {
  checkLastPopupDate,
  checkLastSubDate,
  closeSubscription,
  hideSideSocialBar,
  setSubscribed,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CHECK_LAST_POPUP_DATE]: (state) => {
    // look for last access date in local storage
    const lastDate = window.localStorage.getItem('lastPopupDate')

    let keepSubscriptionClosed = true

    if (lastDate) {
      const diffDays = differenceInDays(new Date(), new Date(lastDate))

      if (diffDays >= 7) keepSubscriptionClosed = false
    }

    return {
      ...state,
      keepSubscriptionClosed,
    }
  },
  [SET_SUBSCRIPTION]: (state, { bool }) => {
    if (bool) {
      window.localStorage.setItem('lastPopupDate', new Date())
    }

    return {
      ...state,
      keepSubscriptionClosed: bool,
    }
  },
  [HIDE_SIDE_SOCIAL_BAR]: (state, { bool }) => ({
    ...state,
    hideSideSocialBar: bool,
  }),
  [SET_SUBSCRIBED]: (state, { bool }) => {
    if (bool) {
      window.localStorage.setItem('lastSubscribedDate', new Date())
    }

    return {
      ...state,
      isSubscribed: bool,
    }
  },
  [CHECK_LAST_SUB_DATE]: (state) => {
    // look for last sub date in local storage
    const lastDate = window.localStorage.getItem('lastSubscribedDate')

    let isSubscribed = false

    if (lastDate) {
      const diffDays = differenceInDays(new Date(), new Date(lastDate))

      if (diffDays <= 14) isSubscribed = true
    }

    return {
      ...state,
      isSubscribed,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
