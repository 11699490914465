import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import GlobalSearch from 'components/GlobalSearch'
import AnimatedText from 'components/AnimatedText'
import SubscriptionTextField from 'components/SubscriptionTextField'
import MenuLinks from 'components/AppBar/MenuLinks'
import Logo from 'assets/images/logo.png'

import BackgroundImage from 'assets/images/landing-background.jpeg'
import { colors } from 'theme'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    marginLeft: 'auto',
    opacity: 0,
    transition: 'opacity 1.5s ease-in-out',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  mounted: {
    opacity: 1,
  },
  heroCntr: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '100%',
    margin: '0px auto',
    zIndex: 200,
    width: '100%',
    position: 'relative',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: '60% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    '&:before': {
      position: 'absolute',
      width: '60%',
      right: 0,
      height: '100%',
      backgroundColor: colors.red.primary,
      opacity: 0.53,
      content: '""',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100vh - 88px)',
      backgroundSize: 'cover',
    },
  },
  heroContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    maxWidth: 1150,
    width: '100%',
    padding: '50px 50px 96px',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-around',
      padding: 24,
    },
  },
  heroContentOffset: {
    marginLeft: 85,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  heroText: {
    fontSize: 24,
    color: '#fff',
    lineHeight: 1.22,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  logoBox: {
    marginBottom: 32,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  logo: {
    height: 48,
    width: 142,
  },
  menu: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  links: {
    display: 'inline-block',
    marginBottom: 16,
  },
  landingText: {
    marginTop: 40,
  },
  followUs: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  followTextWrapper: {
    marginBottom: 64,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 32,
      fontSize: 16,
    },
  },
  followText: {
    color: '#fff',
  },
  followLinks: {
    maxWidth: 480,
    width: '100%',
  },
  subscription: {
    maxWidth: 'calc(40vw - 50px - 85px - 24px)',
  },
}))

const Landing = (props) => {
  const classes = useStyles()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  const phrase = '*Red* *Thread* *Ventures* (“RTV”) is an investment and advisory community focused on building world class businesses and generating outstanding investment returns.'

  return (
    <div
      className={clsx(
        classes.root,
        { [classes.mounted]: mounted },
      )}
    >
      <div className={classes.heroCntr}>
        <div className={classes.heroContent}>
          <div className={classes.menu}>
            <div className={classes.logoBox}>
              <img className={classes.logo} src={Logo} alt="red-thread-ventures" width="85px" height="auto" />
            </div>
            <div className={classes.heroContentOffset}>
              <Grid container justify="space-between">
                <Grid item>
                  <div className={classes.links}>
                    <MenuLinks />
                  </div>
                </Grid>
                <Grid item>
                  <GlobalSearch />
                </Grid>
              </Grid>
              <div className={classes.subscription}>
                <SubscriptionTextField theme="white" />
              </div>
            </div>
          </div>
          {/*
            One way of applying bold
            */}
          <div className={clsx(classes.landingText, classes.heroContentOffset)}>
            <AnimatedText className={classes.heroText} weight="light">
              {phrase}
            </AnimatedText>
          </div>
        </div>
      </div>
    </div>
  )
}

Landing.propTypes = {}
Landing.defaultProps = {}

export default Landing
