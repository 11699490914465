// display the tags in a breadcrumb style
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Separator from 'assets/images/breadcrumb-separator.png'

const BlogTagList = (props) => {
  const { isLinked, tags } = props
  const matchesTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const variant = matchesTablet ? 'body1' : 'h3'

  return (
    <div onClick={e => { e.stopPropagation() }}>
      <Breadcrumbs maxItems={3} separator={<img src={Separator} alt="separator" width={22} height={22} />}>
        {
          tags.map(x => (
            isLinked
              ? (
                <Link key={x} to="/blog">
                  <Typography color="primary" variant={variant}>{x}</Typography>
                </Link>
              )
              : <Typography key={x} color="primary" variant={variant}>{x}</Typography>
          ))
        }
      </Breadcrumbs>
    </div>
  )
}

BlogTagList.propTypes = {
  // if true, tag is a link otherwise just a text
  isLinked: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
}

BlogTagList.defaultProps = {
  isLinked: false,
  tags: [],
}

export default BlogTagList

