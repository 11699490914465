import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory, useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import pluralize from 'pluralize'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import FileIcon from '@material-ui/icons/InsertDriveFile'
import FolderIcon from '@material-ui/icons/Folder'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import LinkIcon from '@material-ui/icons/Link'
import { fetchResource, formatBytes } from '../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 30px 50px',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px 50px',
    },
  },
  grid: {
    flex: 1,
    marginTop: 30,
    marginBottom: 30,
  },
  list: {
    margin: '30px 0px',
  },
  media: {
    height: 150,
  },
  backBtn: {
    alignItems: 'start',
  },
  filesLengthText: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  fileThumb: {
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileIcon: {
    fontSize: 80,
    color: 'rgba(0, 0, 0, 0.23)',
  },
}))

const ResourceItem = (props) => {
  const classes = useStyles()
  const params = useParams()
  const history = useHistory()
  const [shareTooltip, setShareTooltip] = useState(null)
  const [resource, setResource] = useState(null)

  useEffect(() => {
    const getResource = async () => {
      try {
        const res = await fetchResource(params.urlPermalink)

        setResource(res)

        if (!res) {
          history.push('/resources')
        }
      } catch (err) {
        console.log(err)
      }
    }

    getResource()
  }, [])

  const handleOnCardClick = (url) => {
    window.open(url, '_blank')
  }

  const handleOnClickShare = async (e, assetUrl, index) => {
    const validURL = new URL(assetUrl, 'https://redthread.ventures')

    try {
      await navigator.clipboard.writeText(validURL)
      setShareTooltip(index)

      const id = setTimeout(() => {
        setShareTooltip(null)
        clearTimeout(id)
      }, 1500)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className={classes.root}>
      <Box mb={6}>
        <Link to="/resources">
          <Button className={classes.backBtn}>Back to Resources</Button>
        </Link>
      </Box>
      {
        resource ? (
          <>
            <Typography variant="h2" color="secondary">
              <b>{resource.title}</b>
              <Tooltip
                open={shareTooltip === true}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied link!"
              >
                <IconButton onClick={(e) => { handleOnClickShare(e, window.location, true) }}><LinkIcon /></IconButton>
              </Tooltip>
            </Typography>
            <Grid container spacing={1}>
              <Grid item><FolderIcon color="disabled" /></Grid>
              <Grid item><Typography className={classes.filesLengthText}>{resource.files.length} {pluralize('file', resource.files.length)}</Typography></Grid>
            </Grid>
            <div className={classes.grid}>
              <Grid container spacing={5}>
                {
                  resource.files.map((x, i) => {
                    const { fields, sys } = x

                    return (
                      <Grid key={sys.id} item xs={12} sm={6} lg={4}>
                        <Card>
                          <CardActionArea onClick={() => { handleOnCardClick(fields.file.url) }}>
                            {
                              fields.file.contentType.includes('image') ? (
                                <CardMedia
                                  className={classes.media}
                                  image={fields.file.url}
                                  title={fields.file.fileName}
                                />
                              ) : (
                                <div>
                                  <div className={classes.fileThumb}>
                                    <FileIcon className={classes.fileIcon} />
                                  </div>
                                  <Divider />
                                </div>
                              )
                            }
                            <CardContent>
                              <Typography variant="h6" component="h2">{fields.file.fileName}</Typography>
                              <Typography variant="body2">{formatBytes(fields.file.details.size)}</Typography>
                            </CardContent>
                          </CardActionArea>
                          <Divider />
                          <CardActions>
                            <Tooltip
                              PopperProps={{ disablePortal: true }}
                              open={shareTooltip === i} // TODO: DO NOT USE INDEX
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title="Copied link!"
                            >
                              <Button size="small" onClick={(e) => { handleOnClickShare(e, fields.file.url, i) }}>Share</Button>
                            </Tooltip>
                            <Button size="small" onClick={() => { saveAs(fields.file.url, fields.file.fileName) }}>Download</Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </div>
          </>
        ) : <div>Loading</div>
      }
    </div>
  )
}

ResourceItem.propTypes = {}
ResourceItem.defaultProps = {}

export default ResourceItem
