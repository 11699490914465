import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  video: {
    width: '100%',
  },
})

const BlogVideo = (props) => {
  const { file } = props
  const classes = useStyles()

  return (
    <video controls className={classes.video} preload="metadata">
      <source src={file.url} type={file.contentType} />
    </video>
  )
}

BlogVideo.propTypes = {}
BlogVideo.defaultProps = {}

export default BlogVideo
