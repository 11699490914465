import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextButton from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBackOutlined'
import ArrowNext from '@material-ui/icons/ArrowForwardOutlined'
import createPostURL from 'utils/createPostURL'

import LazyImage from 'components/LazyImage'
import Typography from 'components/Typography'
import BlogTitle from './BlogTitle'

const useStyles = makeStyles({
  image: {
    maxHeight: 210,
    height: '100%',
  },
})

const BlogPreviousNext = (props) => {
  const { previousPost, nextPost } = props
  const classes = useStyles()

  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        {
          previousPost
            ? (
              <>
                <Link to={createPostURL(previousPost.urlPermalink)}>
                  <TextButton startIcon={<ArrowBack color="secondary" />}>
                    <Typography color="secondary" weight="bold">Previous</Typography>
                  </TextButton>
                </Link>
                <BlogTitle fontSize="small" title={previousPost.title} />
                <LazyImage src={previousPost.heroImage.file.url} alt={previousPost.title} />
              </>
            )
            : null
        }
      </Grid>
      <Grid item xs={6}>
        {
          nextPost
            ? (
              <>
                <Grid container justify="flex-end">
                  <Link to={createPostURL(nextPost.urlPermalink)}>
                    <TextButton endIcon={<ArrowNext color="secondary" />}>
                      <Typography color="secondary" weight="bold">Next</Typography>
                    </TextButton>
                  </Link>
                </Grid>
                <BlogTitle fontSize="small" title={nextPost.title} />
                <LazyImage src={nextPost.heroImage.file.url} alt={nextPost.title} />
              </>
            )
            : null
        }
      </Grid>
    </Grid>
  )
}

BlogPreviousNext.propTypes = {}
BlogPreviousNext.defaultProps = {}

export default BlogPreviousNext
