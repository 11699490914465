/**
 * 📑 USE GUIDE
 * This component only accepts text as one string.
 *
 * BOLDING:
 * If you need to apply bold to specific words, wrap the word(s) with an asterisk *.
 * If there are multiple words that need to be bolded, there are two solutions:
 * 1) Replace the spaces in the target set of words with &nbsp;. ex. "... *group&nbsp;of&nbsp;words* ..."
 * 2) Wrap EACH word with the asterisk. ex. "... *group* *of* *words* ..."
 *
 * CAVEATS:
 * - Bolded words cannot end on a non-bolded character. This means that ending punctuation for that set will
 * be bold, otherwise there will be a space between the bolded words and the punctuation.
 *
 * ex. *group,* vs *group*,
 * The latter will be rendered as "group ," with the word 'group' bolded.
 */
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'components/Typography'

const useStyles = makeStyles({
  wrapper: {
    display: 'inline-block',
    overflow: 'hidden',
  },
  textHidden: ({ delay }) => ({
    transform: 'translateY(100%)',
    transition: `transform 800ms ${delay}`,
  }),
  textShown: () => ({
    transform: 'translateY(0%)',
  }),
  autoSpacing: {
    // This is only an approx. using the default width of a space character.
    // It changes depending on the font properties.
    marginRight: '0.25em',
  },
})

const AnimatedText = (props) => {
  const {
    show,
    delay,
    children,
    className,
    ...rest
  } = props
  const classes = useStyles({ delay })
  const [showText, setShowText] = useState(false)

  useEffect(() => {
    if (show !== undefined) {
      // use the show from props if available
      setShowText(show)
    } else {
      setShowText(true)
    }
  }, [show])

  const sanitizeString = (str) => {
    // returns an array of clean strings and their markings

    // identify bold mark
    const isBoldMarked = str[0] === '*' && str[str.length - 1] === '*'

    const cleanString = isBoldMarked ? str.replace(/\*/g, '') : str

    // replace &nbsp;
    const separatedStrings = cleanString.split(/&nbsp;/g)

    return separatedStrings.map(x => ({
      clean: x,
      marks: {
        bold: isBoldMarked,
      },
    }))
  }

  const sanitizePhrase = (phrase) => {
    let cleanPhrase = []
    const splitPhrase = phrase.split(' ')

    splitPhrase.forEach((subPhrase) => {
      const sanitizedString = sanitizeString(subPhrase)

      cleanPhrase = [...cleanPhrase, ...sanitizedString]
    })

    return cleanPhrase
  }

  const splitText = sanitizePhrase(children)

  return (
    // Not sure if top level div requires classes.wrapper or not (works for now?)
    <div>
      {
        splitText.map((x, i) => (
          <div key={`${x}-${i}`} className={classes.wrapper}>
            <div
              className={clsx(
                classes.textHidden,
                { [classes.textShown]: showText },
              )}
            >
              <Typography
                className={clsx(
                  className,
                  classes.autoSpacing,
                )}
                {...rest}
                weight={x.marks.bold ? 'bold' : rest.weight}
              >
                {`${x.clean}`}
              </Typography>
            </div>
          </div>
        ))
      }
    </div>
  )
}

AnimatedText.propTypes = {
  show: PropTypes.bool,
  // delay in seconds or milliseconds
  delay: PropTypes.string,
  children: PropTypes.string.isRequired,
}

AnimatedText.defaultProps = {
  show: undefined,
  delay: '1s',
}

export default AnimatedText
