import { fetchBlogPosts } from '../blog.module'

export default async () => {
  try {
    const res = await fetchBlogPosts()

    const data = res.map(x => ({
      title: x.title,
      content: [x.subtitle, x.author].join(' '),
      path: `/blog/${x.id}`,
    }))

    data.push({
      title: 'Blog',
      content: 'Follow blog post and news from Red Thread Ventures.',
      path: '/blog',
    })

    return data
  } catch (err) {
    console.log(err)
    return []
  }
}
