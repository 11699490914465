import AboutUs from '../AboutUs'
import Landing from '../Landing'
import Contact from '../Contact'
import InvestorLogin from '../InvestorLogin'
import Portfolio from '../Portfolio'
import Blog from '../Blog'
import Podcast from '../Podcast'
import Resources from '../Resources'

const useRoutes = () => {
  const routes = [
    {
      label: 'Landing',
      path: '/',
      component: Landing,
      exact: true,
      hidden: true,
    },
    {
      label: 'About Us',
      path: '/about-us',
      component: AboutUs,
    },
    {
      label: 'Contact',
      path: '/contact',
      component: Contact,
    },
    {
      label: 'Blog',
      path: '/blog',
      component: Blog,
    },
    {
      label: 'Portfolio',
      path: '/portfolio',
      component: Portfolio,
    },
    {
      label: 'Investor Network',
      path: '/investor-network',
      component: InvestorLogin,
    },
    {
      label: 'Podcasts',
      path: '/podcasts',
      component: Podcast,
    },
    {
      label: 'Resources',
      path: '/resources',
      component: Resources,
    },
  ]

  return routes
}

export default useRoutes
