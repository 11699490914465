import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  wrapper: {
    overflow: 'hidden',
  },
  hidden: ({ direction, delay }) => ({
    transform: `translateY(${direction === 'up' ? '100%' : '-100%'})`,
    transition: `transform 800ms ${delay}`,
  }),
  // why does this need to be a function to have higher specificity than hidden?
  shown: () => ({
    transform: 'translateY(0%)',
  }),
})

const AnimatedNode = (props) => {
  const { show, direction, delay, children } = props
  const classes = useStyles({ direction, delay })
  const [showNode, setShowNode] = useState(false)

  useEffect(() => {
    if (show !== undefined) {
      // use the show from props if available
      setShowNode(show)
    } else {
      setShowNode(true)
    }
  }, [show])

  return (
    <div className={classes.wrapper}>
      <div
        className={clsx(
          classes.hidden,
          { [classes.shown]: showNode },
        )}
      >
        {children}
      </div>
    </div>
  )
}

AnimatedNode.propTypes = {
  show: PropTypes.bool,
  delay: PropTypes.string,
  direction: PropTypes.oneOf([
    'up',
    'down',
  ]),
  children: PropTypes.node.isRequired,
}

AnimatedNode.defaultProps = {
  direction: 'up',
  delay: '0s',
  show: undefined,
}

export default AnimatedNode
