import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { colors } from 'theme'

const useStyles = makeStyles({
  root: {
    background: 'none',
    border: 'none',
    borderRadius: 28,
    minWidth: 160,
    cursor: 'pointer',
    padding: '0px 50px',
  },
  primary: {
    backgroundColor: colors.red.primary,
    color: '#fff',
  },
  secondary: {
    borderColor: colors.red.primary,
    borderWidth: 1,
    borderStyle: 'solid',
    color: colors.red.primary,
  },
  normal: {
    height: 48,
  },
  small: {
    height: 40,
  },
})

const Button = ({
  variant = 'primary',
  height = 'normal',
  className,
  ...props
}) => {
  const classes = useStyles()

  return (
    <button
      className={clsx(
        classes.root,
        classes[variant],
        classes[height],
        className,
      )}
      type="button"
      {...props}
    />
  )
}

Button.propTypes = {}
Button.defaultProps = {}

export default Button
