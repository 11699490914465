import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  root: {
    marginTop: 10,
  },
  chip: ({ dark }) => ({
    padding: '3px 10px',
    borderRadius: '3px',
    backgroundColor: dark ? 'rgba(33, 33, 33, 0.7)' : '#fff',
  }),
  text: ({ dark }) => ({
    color: dark ? '#fff' : '#333',
  }),
})

const BlogIndustryList = (props) => {
  const { dark, industry = [] } = props
  const classes = useStyles({ dark })

  return (
    <div className={classes.root}>
      <Grid container wrap="wrap" spacing={2}>
        {industry.map(x => <Grid item><div className={classes.chip}><Typography className={classes.text} variant="caption">{x}</Typography></div></Grid>)}
      </Grid>
    </div>
  )
}

BlogIndustryList.propTypes = {}
BlogIndustryList.defaultProps = {}

export default BlogIndustryList
