import React from 'react'
import contentful from 'utils/contentful'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

export default async () => {
  let podcasts = []

  try {
    podcasts = await contentful.getEntries({
      content_type: 'podcast',
    })

    const parseOptions = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: children }} />,
      },
    }

    podcasts = podcasts.items.map((post) => {
      const { sys, fields } = post

      return {
        /**
        * @note
        * array of podcasts
        * {
        * embedURL: Rich Text Content,
        * title: String,
        * }
        */
        ...fields,
        parsedBody: documentToReactComponents(fields.embedUrl, parseOptions),
        id: sys.id,
        createdAt: sys.createdAt,
        updatedAt: sys.updatedAt,
      }
    })
    // }).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  } catch (err) {
    console.log(err)
  }
  return podcasts
}
