import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AnimatedText from 'components/AnimatedText'
import MenuLinks from 'components/AppBar/MenuLinks'
import Logo from 'assets/images/logo.png'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from 'components/Button'
import TypeComponent from 'components/Typography'
import ContactInfo from 'components/ContactInfo'
import AnimatedNode from 'components/AnimatedNode'
import Visible from 'components/Visible'

import ConnectedHandsImage from 'assets/images/ConnectedHands.png'
import LocationImage from 'assets/images/contact_location.png'
import MobileBackgroundImage from 'assets/images/contact_mobile_background.png'
import MobileBackgroundCoverImage from 'assets/images/contact_mobile_background_cover.png'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      zIndex: 0,
      content: '""',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      opacity: 0.1,
      backgroundImage: `url(${ConnectedHandsImage})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
  },
  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileContainer: {
    height: '100vh',
    width: 'auto',
    marginTop: -88,
    backgroundImage: `url(${MobileBackgroundImage})`,
    backgroundSize: 'cover',
    position: 'relative',
  },
  backgroundCover: {
    backgroundImage: `url(${MobileBackgroundCoverImage})`,
    backgroundSize: 'cover',
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0.5,
  },
  headerContainer: {
    minHeight: '100vh',
    padding: '120px 0px 130px',
    [theme.breakpoints.down('xs')]: {
      padding: '88px 30px',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
  },
  headerText: {
    fontSize: 48,
    lineHeight: 1.22,
    fontVariationSettings: '"wght" 700',
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
      lineHeight: 1.5,
      color: '#ff5858',
    },
  },
  sectionMargin: {
    marginTop: 88,
  },
  mobileSectionMargin: {
    marginTop: 0,
    paddingTop: 50,
  },
  text: {
    display: 'block',
    lineHeight: 1.75,
    fontSize: 16,
    fontVariationSettings: '"wght" 700',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  textMargin: {
    marginTop: 32,
  },
  mapButton: {
    height: 57,
    minWidth: 278,
    [theme.breakpoints.down('xs')]: {
      height: 48,
    },
  },
  mobileDetails: {
    height: '100vh',
    width: '100vw',
  },
  locationLink: {
    maxWidth: 180,
  },
}))


const Contact = (props) => {
  const classes = useStyles()
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const ContactDetails = () => (
    <Visible once>
      {
        ({ isVisible }) => (
          <AnimatedNode direction="down" show={isVisible}>
            <Grid container className={clsx(classes.sectionMargin, matchesMobile && classes.mobileSectionMargin)} spacing={4} justify={matchesMobile ? 'center' : 'flex-start'}>
              {
                matchesMobile ? null :
                  <Grid item>
                    <img src={LocationImage} width="auto" height={30} alt="image-location-pin" />
                  </Grid>
              }
              <Grid item>
                <ContactInfo type="location" className={clsx(classes.text, classes.locationLink)} />
                <ContactInfo type="email" className={clsx(classes.text, classes.textMargin)} />
                <ContactInfo type="phone" className={classes.text} />
                <ContactInfo type="fax" className={classes.text} />
              </Grid>
            </Grid>
            <Grid container className={classes.sectionMargin} spacing={4} justify={matchesMobile ? 'center' : 'flex-start'}>
              <Grid item>
                <Button
                  variant="secondary"
                  className={classes.mapButton}
                  onClick={()=> window.open("https://www.google.ca/maps/search/375+Water+St.+%23200,+Vancouver,+BC+V6B+5C/@49.2848948,-123.1127207,17z/data=!3m1!4b1", "_blank")}
                >
                  <TypeComponent variant={matchesMobile ? 'body1' : 'h3'}>
                    Show on map
                  </TypeComponent>
                </Button>
              </Grid>
            </Grid>
          </AnimatedNode>
        )
      }
    </Visible>
  )

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <AnimatedText className={classes.headerText}>Contact us</AnimatedText>
        <ContactDetails />
      </div>
    </div>
  )
}

Contact.propTypes = {}
Contact.defaultProps = {}

export default Contact
