import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  image: {
    width: '100%',
    objectFit: 'cover',
  },
})

const LazyImage = (props) => {
  const { src, alt, className } = props
  const classes = useStyles()

  return <img src={src} alt={alt} className={clsx(classes.image, className)} />
}

LazyImage.propTypes = {}
LazyImage.defaultProps = {}

export default LazyImage
