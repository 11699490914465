import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import Twitter from 'assets/icons/twitter.svg'
import TwitterBlack from 'assets/icons/Twitter_Black.png'
import TwitterRedFilled from 'assets/icons/Twitter_Red_Filled.png'
import TwitterRedOutlined from 'assets/icons/Twitter_Red_Outlined.png'

import LinkedIn from 'assets/icons/linkedin.svg'
import LinkedInBlack from 'assets/icons/LinkedIn_Black.png'
import LinkedInRedFilled from 'assets/icons/LinkedIn_Red_Filled.png'
import LinkedInRedOutlined from 'assets/icons/LinkedIn_Red_Outlined.png'

import FBBlack from 'assets/icons/FB_Black.png'
import FBRedFilled from 'assets/icons/FB_Red_Filled.png'
import FBRedOutlined from 'assets/icons/FB_Red_Outlined.png'

import IG from 'assets/icons/instagram.svg'
import IGBlack from 'assets/icons/IG_Black.png'
import IGRedFilled from 'assets/icons/IG_Red_Filled.png'
import IGRedOutlined from 'assets/icons/IG_Red_Outlined.png'

import Crunchbase from 'assets/icons/crunchbase-icon.svg'

import Share from 'assets/icons/share_blk.svg'

import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'

const useStyles = makeStyles({
  image: ({ size }) => {
    const mapSizeToDimensions = {
      small: {
        width: 18,
        height: 18,
      },
      normal: {
        width: 24,
        height: 24,
      },
      large: {
        width: 32,
        height: 32,
      },
    }

    return {
      backgroundColor: '#ff5858',
      borderRadius: '50%',
      ...mapSizeToDimensions[size],
    }
  },
  shareIcon: {
    opacity: 0.8,
    backgroundColor: 'transparent !important',
    borderRadius: '0px !important',
  },
  borderOffset: {
    padding: 2,
  },
})

const SocialMediaBar = (props) => {
  const [open, setOpen] = useState(false)
  const {
    url,
    title,
    theme,
    hashtags,
    description,
    orientation,
    follow,
    size,
    buttonSize,
    ContainerProps,
  } = props
  const classes = useStyles({ size })

  const joinedHashtags = hashtags.join(',')
  const href = `${window.location.origin}${url}`

  const handleCopyToClipboard = async (e) => {
    e.stopPropagation()

    try {
      await navigator.clipboard.writeText(href)
      setOpen(true)

      const id = setTimeout(() => {
        setOpen(false)
        clearTimeout(id)
      }, 1500)
    } catch (err) {
      console.log(err)
    }
  }

  const icons = [
    'twitter',
    'linkedIn',
    'facebook',
  ]

  if (follow) {
    icons.push('instagram', 'crunchbase')
  }

  const mapIconToTheme = {
    twitter: {
      normal: Twitter,
      black: TwitterBlack,
      outlined: TwitterRedOutlined,
      filled: TwitterRedFilled,
    },
    linkedIn: {
      normal: LinkedIn,
      black: LinkedInBlack,
      outlined: LinkedInRedOutlined,
      filled: LinkedInRedFilled,
    },
    facebook: {
      normal: FBBlack,
      black: FBBlack,
      outlined: FBRedOutlined,
      filled: FBRedFilled,
    },
    instagram: {
      normal: IGBlack,
      black: IGBlack,
      outlined: IGRedOutlined,
      filled: IGRedFilled,
    },
    crunchbase: {
      normal: Crunchbase,
      black: Crunchbase,
      outlined: Crunchbase,
      filled: Crunchbase,
    },
  }

  const mapIconToShareLink = {
    twitter: `https://twitter.com/intent/tweet?text=${title}&url=${href}&hashtags=${joinedHashtags}`,
    linkedIn: `https://www.linkedin.com/sharing/share-offsite/?url=${href}`,
    facebook: `https://www.facebook.com/sharer.php?u=${href}`,
    instagram: '',
    crunchbase: '',
  }

  const mapIconToFollowLink = {
    linkedIn: 'https://www.linkedin.com/company/red-thread-ventures',
    twitter: 'https://twitter.com/RedThreadVC',
    facebook: 'https://www.facebook.com/Red-Thread-Ventures-100660728382618/',
    instagram: 'https://www.instagram.com/redthreadventures/',
    crunchbase: 'https://www.crunchbase.com/organization/red-thread-ventures',
  }

  const mapOrientationToDirection = {
    vertical: 'column',
    horizontal: 'row',
  }

  const trackClick = (e, { label }) => {
    e.stopPropagation()
    gtag(
      'event',
      follow ? 'follow' : 'share',
      {
        event_category: 'engagement',
        event_label: label,
      },
    )
  }

  return (
    <Grid container direction={mapOrientationToDirection[orientation]} {...ContainerProps}>
      {
        icons.map(x => (
          <Grid key={x} item>
            <a href={follow ? mapIconToFollowLink[x] : mapIconToShareLink[x]} target="_blank" rel="noopener noreferrer" aria-label={title}>
              <IconButton
                onClick={(e) => {
                  trackClick(e, { label: follow ? x : mapIconToShareLink[x] })
                }}
                size={buttonSize}
              >
                <img
                  className={clsx(
                    classes.image,
                    { [classes.borderOffset]: x === 'crunchbase' },
                  )}
                  src={mapIconToTheme[x][theme]}
                  alt="Red Thread Venture"
                />
              </IconButton>
            </a>
          </Grid>
        ))
      }
      {
        !follow && (
          <Tooltip
            PopperProps={{ disablePortal: true }}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied link!"
          >
            <IconButton onClick={handleCopyToClipboard}>
              <img className={clsx(classes.image, classes.shareIcon)} src={Share} alt="Share this URL" />
            </IconButton>
          </Tooltip>
        )
      }
    </Grid>
  )
}

SocialMediaBar.propTypes = {
  url: PropTypes.string,
  hashtags: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  orientation: PropTypes.oneOf([
    'vertical',
    'horizontal',
  ]),
  theme: PropTypes.oneOf([
    'normal',
    'black',
    'outlined',
    'filled',
  ]),
  size: PropTypes.oneOf([
    'small',
    'normal',
    'large',
  ]),
  buttonSize: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  follow: PropTypes.bool,
  ContainerProps: PropTypes.object,
}

SocialMediaBar.defaultProps = {
  url: window.location.href,
  hashtags: [],
  title: '',
  orientation: 'vertical',
  theme: 'filled',
  size: 'normal',
  buttonSize: 'medium',
  follow: false,
  ContainerProps: {},
}

export default SocialMediaBar
