import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import LaunchIcon from '@material-ui/icons/Launch'
import fetchCompanies from './utils'

const useCompanyItemStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    height: '100%',
  },
  companyImage: ({ thumbnail }) => ({
    width: '100%',
    paddingBottom: '100%',
    backgroundImage: `url(${thumbnail})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }),
  nameCard: ({ hasUrl }) => ({
    position: 'absolute',
    zIndex: 10,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: 'backdrop-filter 150ms, background-color 150ms',
    padding: 12,
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        backgroundColor: 'rgba(100, 100, 100, 0.96)',
        cursor: hasUrl ? 'pointer' : 'default',
        '& p': {
          opacity: 1,
        },
      },
    },
  }),
  nameText: {
    transition: 'opacity 150ms',
    opacity: 0,
    fontSize: 18,
    textAlign: 'center',
    color: '#fff',
  },
  launchIcon: {
    verticalAlign: 'text-bottom',
  },
  noImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 64,
    color: 'rgba(0, 0, 0, 0.7)',
    backgroundColor: '#fff',
    fontWeight: 'bold',
  },
}))

const CompanyItem = (props) => {
  const { company = {} } = props
  const classes = useCompanyItemStyles({
    thumbnail: company?.thumbnail?.file?.url,
    hasUrl: Boolean(company.url),
  })

  return (
    <a href={company.url} target="_blank" rel="noopener noreferrer">
      <div className={classes.card}>
        <Grid
          container
          alignItems="center"
          justify="center"
          classes={{ container: classes.nameCard }}
        >
          <Grid item>
            <Typography className={classes.nameText}>
              {company.companyName} <LaunchIcon className={classes.launchIcon} fontSize="small" />
            </Typography>
          </Grid>
        </Grid>
        {
          company.thumbnail ? (
            <div className={classes.companyImage} />
          ) : <div className={classes.noImage}>{company.companyName[0]}</div>
        }
      </div>
    </a>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
    margin: '0px auto',
    minHeight: '100vh',
    padding: '90px 90px 50px',
    [theme.breakpoints.down('md')]: {
      padding: '50px 50px 50px',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 'unset',
      padding: '50px 0px 50px',
    },
  },
  title: {
    fontSize: 32,
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px 0px',
    },
  },
  container: {
    paddingTop: 30,
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px 0px',
      backgroundColor: '#f4f5f7',
    },
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
}))

const Portfolio = (props) => {
  const classes = useStyles()
  const [companies, setCompanies] = useState([])

  useEffect(() => {
    const getCompanies = async () => {
      try {
        const res = await fetchCompanies()

        setCompanies(res)
      } catch (err) {
        console.log(err)
      }
    }

    getCompanies()
  }, [])

  const Subtitle = (pr) => <Typography gutterBottom className={classes.subtitle}>{pr.children}</Typography>
  const Section = (pr) => <Grid item xs={12} sm={6} md={4} lg={3}>{pr.children}</Grid>

  const vccComps = useMemo(() => {
    return companies.filter(c => c.companyType)
  }, [companies])

  const nonVccComps = useMemo(() => {
    return companies.filter(c => !c.companyType)
  }, [companies])

  return (
    <div className={classes.root}>
      <Typography color="secondary" className={classes.title}>
        <b>Portfolio</b>
      </Typography>
      {
        nonVccComps.length ? (
          <div className={classes.container}>
            <Subtitle>VCC Fund (Venture Capital Corporation)</Subtitle>
            <Grid container>
              {
                nonVccComps.map(c => (
                  <Section key={c.id}>
                    <CompanyItem company={c} />
                  </Section>
                ))
              }
            </Grid>
          </div>
        ) : null
      }
      {
        vccComps.length ? (
          <div className={classes.container}>
            <Subtitle>Global Fund</Subtitle>
            <Grid container>
              {
                vccComps.map(c => (
                  <Section key={c.id}>
                    <CompanyItem company={c} />
                  </Section>
                ))
              }
            </Grid>
          </div>
        ) : null
      }
    </div>
  )
}

Portfolio.propTypes = {}
Portfolio.defaultProps = {}

export default Portfolio
