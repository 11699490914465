import contentful from 'utils/contentful'

export default async () => {
  let companies = []

  try {
    companies = await contentful.getEntries({
      content_type: 'portfolioCompany',
    })

    companies = companies.items.map((post) => {
      const { sys, fields } = post

      // update structure for a company
      return {
        /**
        * @note
        * array of posts
        * struture of single post for list page:
        * {
        * title: String,
        * description: String,
        * createdAt: Date,
        * heroImage: {
        *    title: String,
        *    file: { url, details, fileName, contentType },
        * },
        * tags: [],
        * }
        */
        ...fields,
        thumbnail: fields.thumbnail?.fields,
        id: sys.id,
        createdAt: sys.createdAt,
        updatedAt: sys.updatedAt,
      }
    })
  } catch (err) {
    console.log(err)
  }
  return companies
}
