import React, { useEffect, useState, useRef } from 'react'
import Fuse from 'fuse.js'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import SearchIcon from '@material-ui/icons/Search'

import SearchResults from './GlobalSearchResults'
import { dataForGlobalSearch as AboutUsData } from '../../AboutUs/AboutUs'
import { dataForGlobalSearch as InvestorData } from '../../InvestorLogin/InvestorLogin'

import getBlogData from '../../Blog/components/BlogPostsForGlobalSearch'

const useStyles = makeStyles(theme => ({
  inputColor: {
    color: '#fff',
  },
  textRoot: {
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
  },
  icon: {
    color: '#fff',
  },
  tooltip: {
    maxWidth: '95vw ',
    padding: '10px 0',
    backgroundColor: 'rgba(97, 97, 97, 1.0)',
    boxShadow: theme.shadows[6],
  },
}))

const GlobalSearch = (props) => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('')
  const inputRef = useRef(null)
  const classes = useStyles()

  const [data, setData] = useState([
    ...AboutUsData(),
    ...InvestorData(),
  ])

  useEffect(() => {
    const getBlogForGlobalSearch = async () => {
      try {
        const blogData = await getBlogData()

        setData([...data, ...blogData])
      } catch (err) {
        console.log(err)
      }
    }

    getBlogForGlobalSearch()
  }, [])

  const trackSearch = (keyword) => {
    gtag(
      'event',
      keyword,
      {
        event_category: 'engagement',
        event_label: 'on-site-search',
      },
    )
  }

  const debouncedTracker = useRef(debounce(trackSearch, 500))

  useEffect(() => {
    if (value.length > 2) {
      debouncedTracker.current(value)
    }
  }, [value])

  const onChange = (e) => {
    setValue(e.target.value)
    setOpen(e.target.value.length > 2)
  }

  const onClickAway = () => {
    setOpen(false)
    setValue('')
  }

  const fuse = new Fuse(data, {
    minMatchCharLength: 2,
    keys: [
      {
        name: 'title',
        weight: 3,
      },
      {
        name: 'content',
        weight: 2,
      },
      'path',
    ],
  })

  const filteredByQuery = fuse.search(value)

  return (
    <div>
      <ClickAwayListener onClickAway={onClickAway}>
        <Tooltip
          arrow
          open={open}
          interactive
          classes={{ tooltip: classes.tooltip }}
          title={(
            <SearchResults
              results={filteredByQuery}
              query={value}
              onClose={() => { setOpen(false) }}
            />
          )}
        >
          <TextField
            fullWidth
            value={value}
            onChange={onChange}
            placeholder="Search the site"
            classes={{ root: classes.textRoot }}
            ref={inputRef}
            InputProps={{
              classes: {
                root: classes.inputColor,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.icon} />
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </ClickAwayListener>
    </div>
  )
}

GlobalSearch.propTypes = {}
GlobalSearch.defaultProps = {}

export default GlobalSearch
