import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { DataGrid } from '@material-ui/data-grid'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import fetchResources from '../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px',
    height: '100vh',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 88px)',
      padding: '30px 20px',
    },
  },
  grid: {
    flex: 1,
    marginTop: 30,
  },
  list: {
    margin: '30px 0px',
  },
  title: {
    textDecoration: 'underline',
  },
  row: {
    cursor: 'pointer',
  },
}))

const MoreMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grid container>
      <Grid item>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>Download</MenuItem>
          <MenuItem>Copy Link</MenuItem>
        </Menu>
      </Grid>
    </Grid>
  )
}

const ResourceList = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const [resources, setResources] = useState([])

  useEffect(() => {
    const getResources = async () => {
      try {
        const res = await fetchResources()

        setResources(res)
      } catch (err) {
        console.log(err)
      }
    }

    getResources()
  }, [])

  const gridCols = [
    {
      field: 'title',
      headerName: 'File',
      sortable: true,
      flex: 1,
      renderCell: (props) => {
        return (
          <Typography className={classes.title}>{props.value}</Typography>
        )
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Last Updated',
      sortable: true,
      width: 150,
    },
  ]

  const handleRowClick = (rowParam) => {
    history.push(`/resources/${rowParam.row.link}`)
  }

  const gridRows = useMemo(() => resources.map((x) => ({ id: x.id, title: x.title, updatedAt: format(new Date(x.updatedAt), 'MMMM dd, yyyy'), link: x.urlPermalink })), [resources])

  return (
    <div className={classes.root}>
      <Typography variant="h2" color="secondary"><b>Resources</b></Typography>
      <div className={classes.grid}>
        <DataGrid
          columns={gridCols}
          rows={gridRows}
          autoPageSize
          disableColumnMenu
          onRowClick={handleRowClick}
          rowHeight={64}
          classes={{
            row: classes.row,
          }}
        />
      </div>
    </div>
  )
}

ResourceList.propTypes = {}
ResourceList.defaultProps = {}

export default ResourceList
