import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import config from '../../../tools/config'

const PageTracker = () => {
  const location = useLocation()

  useEffect(() => {
    gtag('config', config.gtag, { // eslint-disable-line
      page_path: location.pathname,
    })
  }, [location.pathname])

  return null
}

export default PageTracker
