import React from 'react'
// import Loadable from 'react-loadable-visibility/react-loadable'
import contentful from 'utils/contentful'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import LazyImage from 'components/LazyImage'
import Typography from 'components/Typography'
import BlogVideo from './components/BlogVideo'

export const fetchBlogPosts = async () => {
  const posts = []

  try {
    const res = await contentful.getEntries({
      content_type: 'newsPost',
    })

    res.items.forEach((post) => {
      try {
        const { sys, fields } = post

        // update structure for list page
        posts.push({
          /**
          * @note
          * array of posts
          * struture of single post for list page:
          * {
          * title: String,
          * description: String,
          * createdAt: Date,
          * heroImage: {
          *    title: String,
          *    file: { url, details, fileName, contentType },
          * },
          * tags: [],
          * }
          */
          ...fields,
          heroImage: fields.heroImage.fields,
          id: sys.id,
          createdAt: sys.createdAt,
          updatedAt: sys.updatedAt,
          industry: fields.industry || [],
          highlight: fields.highlight !== undefined ? fields.highlight : false,
          tags: fields.tags || [],
        })
      } catch (err) {
        console.log(err)
      }
    })

    posts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  } catch (err) {
    console.log(err)
  }
  return posts
}

export const fetchBlogPost = async (urlPermalink) => {
  const options = {
    // https://www.npmjs.com/package/@contentful/rich-text-react-renderer
    // replace \n with <br />
    renderText: text => {
      if (text === '') return [<br key={`${text}-key`} />]
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, file } = node?.data?.target?.fields

        if (file.contentType.includes('video')) {
          return <BlogVideo file={file} />
        }
        if (file.contentType.includes('image')) {
          return (
            <div style={{ margin: '40px 0px' }}>
              <LazyImage src={file.url} alt={title} />
            </div>
          )
        }

        return <Typography>Unsupported File Type {file.contentType}</Typography>
      },
      [BLOCKS.HEADING_1]: (node, children) => <Typography variant="h1">{children}</Typography>,
      [BLOCKS.HEADING_2]: (node, children) => <Typography variant="h2">{children}</Typography>,
      [BLOCKS.HEADING_3]: (node, children) => <Typography variant="h3">{children}</Typography>,
      [BLOCKS.PARAGRAPH]: (node, children) => <Typography>{children}</Typography>,
    },
  }

  let post = null

  try {
    const { items } = await contentful.getEntries({
      content_type: 'newsPost',
      'fields.urlPermalink': urlPermalink,
    })

    post = items[0] // eslint-disable-line

    // parse rich text into React components for easy render
    post.fields.body = documentToReactComponents(post.fields.body, options)
  } catch (err) {
    console.log(err)
  }

  return post
}
