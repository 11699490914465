import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Typography from 'components/Typography'
import Card from 'components/Card'
import CardHover from 'components/Card/CardHover'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    maxHeight: 300,
    maxWidth: 500,
    width: '100%',
    overflowY: 'scroll',
    overscrollBehavior: 'contain',
  },
  card: {
    padding: 20,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: 'rgba(130, 130, 130, 1.0)',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  divider: {
    margin: '16px 0',
  },
  description: {
    maxWidth: 450,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const GlobalSearchResults = (props) => {
  const { results, query, onClose } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {
        results.length
          ? (
            results.map(({ item }, i) => (
              <Fragment key={`${item.title}-${i}`}>
                <Card onClick={onClose} to={item.path} className={classes.card}>
                  <Typography variant="h3">{item.title}</Typography>
                  <Typography className={classes.description} weight="light">{item.content}</Typography>
                </Card>
                {i !== results.length - 1 && <Divider className={classes.divider} />}
              </Fragment>
            ))
          )
          : (
            <Card className={classes.card}>
              <Typography>No results for <Typography component="span" color="secondary">{query}</Typography></Typography>
            </Card>
          )
      }
    </div>
  )
}

GlobalSearchResults.propTypes = {}
GlobalSearchResults.defaultProps = {}

export default GlobalSearchResults
