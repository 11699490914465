import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import useRoutes from 'utils/useRoutes'

import Visible from 'components/Visible'
import SocialMediaBar from 'components/SocialMediaBar'
import ContactInfo from 'components/ContactInfo'
import Twitter from 'assets/icons/twitter.svg'
import LinkedIn from 'assets/icons/linkedin.svg'
import DarkLogo from 'assets/images/darkLogo.png'
import BackgroundImage from 'assets/images/aboutUsMobileBG.png'

import { actions } from '../../app.module'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '64px 40px 30px',
    minHeight: 300,
    [theme.breakpoints.down('xs')]: {
      padding: '48px 20px 24px',
    },
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  content: {
    maxWidth: 832,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 32,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  red: {
    color: '#ff5858',
  },
  cursor: {
    cursor: 'pointer',
  },
  item: {
    alignSelf: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'center',
    },
  },
  linkText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  addressText: {
    maxWidth: 180,
  },
  copyright: {
    backgroundColor: '#fff',
    lineHeight: '64px',
    fontSize: 16,
    fontVariationSettings: '"wght" 700',
  },
  mobileCopyright: {
    marginTop: 8,
    fontVariationSettings: '"wght" 300',
    fontSize: 8,
  },
  mobileItem: {
    '@media (max-width: 500px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  socialIcon: {
    width: 35,
    height: 35,
    [theme.breakpoints.down('xs')]: {
      width: 20,
      height: 20,
    },
  },
  socialMediaBar: {
    marginLeft: -12,
    [theme.breakpoints.down('xs')]: {
      marginTop: 16,
    },
  },
}))

const Footer = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const routes = useRoutes()
  const [isVisible, setVisible] = useState(true)
  const matchesTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  useEffect(() => {
    dispatch(actions.hideSideSocialBar(isVisible))
  }, [isVisible])

  const calcContainerSpacing = () => {
    if (matchesMobile) return 5
    if (matchesTablet) return 3
    return 0
  }

  const calcLinkSpacing = () => {
    if (matchesMobile) return 2
    return 1
  }

  const AddressTypography = ({ children }) => <div className={classes.addressText}>{children}</div>
  const ItemGrid = ({ children }) => <Grid item classes={{ item: classes.item }}>{children}</Grid>

  const SocialMedia = (
    <div className={classes.socialMediaBar}>
      <SocialMediaBar
        follow
        orientation="horizontal"
        size={matchesMobile ? 'normal' : 'large'}
      />
    </div>
  )

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.content}>
          <Grid container spacing={calcContainerSpacing()}>
            <Grid classes={{ item: classes.mobileItem }} item xs={12} sm={4}>
              <Grid container spacing={calcLinkSpacing()} direction="column">
                {
                  routes.map((x) => {
                    if (x.hidden || !x.component) return null

                    return (
                      <ItemGrid key={x.path}>
                        <Link to={x.path}>
                          <Typography className={classes.linkText}>{x.label}</Typography>
                        </Link>
                      </ItemGrid>
                    )
                  })
                }
              </Grid>
            </Grid>
            {
              matchesMobile && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )
            }
            <Grid classes={{ item: classes.mobileItem }} item xs={12} sm={4}>
              <Grid container direction="column">
                <ItemGrid>
                  <AddressTypography>
                    <ContactInfo type="location" weight={matchesMobile ? 'normal' : 'bold'} />
                  </AddressTypography>
                </ItemGrid>
                <br />
                <ItemGrid>
                  <ContactInfo type="email" weight={matchesMobile ? 'normal' : 'bold'} />
                </ItemGrid>
                <ItemGrid>
                  <ContactInfo type="phone" weight={matchesMobile ? 'normal' : 'bold'} />
                </ItemGrid>
                <ItemGrid>
                  <ContactInfo type="fax" weight={matchesMobile ? 'normal' : 'bold'} />
                </ItemGrid>
              </Grid>
            </Grid>
            {
              matchesMobile && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )
            }
            {
              matchesMobile
                ? (
                  <Grid container item xs={12} justify="space-between">
                    <Grid item>
                      <img src={DarkLogo} alt="Red Thread Ventures" width={77} height="auto" />
                      <Typography className={classes.mobileCopyright}>
                        Copyright ©{new Date().getFullYear()} <span className={classes.red}>Red Thread</span> Ventures.
                        <br />
                        Designed and Powered by <a href="https://www.staccc.com/" target="_blank" rel="noopener noreferrer">Staccc Technologies</a>
                      </Typography>
                    </Grid>
                    <Grid item>
                      {SocialMedia}
                    </Grid>
                  </Grid>
                )
                : (
                  <Grid classes={{ item: classes.mobileItem }} item xs={12} sm={4}>
                    <Typography gutterBottom>Follow Us</Typography>
                    <Visible>
                      {
                        ({ isVisible }) => {
                          setVisible(isVisible)
                          return SocialMedia
                        }
                      }
                    </Visible>
                  </Grid>
                )
            }
          </Grid>
        </div>
      </div>
      {!matchesMobile && <Typography className={classes.copyright} align="center">Copyright ©{new Date().getFullYear()} <span className={classes.red}>Red Thread</span> Ventures. Designed and Powered by <a href="https://www.staccc.com/" target="_blank" rel="noopener noreferrer">Staccc Technologies</a></Typography>}
    </div>
  )
}

Footer.propTypes = {}
Footer.defaultProps = {}

export default Footer
