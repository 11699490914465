import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Switch, Route, useLocation, useRouteMatch, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import SubscriptionField from 'components/SubscriptionTextField'
import ResourceList from './ResourceList'
import ResourceItem from './ResourceItem'
import { actions } from '../app.module'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    maxWidth: 1024,
    margin: '0px auto',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    position: 'absolute',
    top: 8,
    right: 12,
  },
  description: {
    marginBottom: 24,
  },
}))

const Resources = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { isSubscribed } = useSelector((state) => state.app)

  useEffect(() => {
    dispatch(actions.checkLastSubDate())
  }, [])

  const handleClose = () => {
    history.push('/')
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Switch>
          <Route exact path="/resources" component={ResourceList} />
          <Route exact path="/resources/:urlPermalink" component={ResourceItem} />
        </Switch>
      </div>
      <Dialog open={!isSubscribed}>
        <DialogTitle>
          Subscribers Only
          <IconButton onClick={handleClose} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography className={classes.description}>
            Enter your email to subscribe to our mailing list and view these resources.
          </Typography>
          <Typography className={classes.description} component="p" variant="body2">
            {"(If you're already subscribed, we won't subscribe you again)"}
          </Typography>
          <SubscriptionField minimized />
        </DialogContent>
      </Dialog>
    </div>
  )
}

Resources.propTypes = {}
Resources.defaultProps = {}

export default Resources
