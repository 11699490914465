import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'Bahnschrift',
      },
    },
    MuiInput: {
      underline: {
        '&&&&:hover:before': {
          borderBottom: '1px solid #fff',
        },
      },
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Bahnschrift',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    body1: {
      fontSize: 16,
    },
    h1: {
      fontSize: 48,
    },
    h2: {
      fontSize: 28,
    },
    h3: {
      fontSize: 20,
    },
    h4: {
      fontSize: 18,
    },
  },
  palette: {
    primary: {
      main: '#212121',
    },
    secondary: {
      main: '#ff5858',
    },
    text: {
      primary: '#212121',
      secondary: '#ff5858',
    },
  },
})
