import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useRoutes from 'utils/useRoutes'

import { colors } from 'theme'

const useStyles = makeStyles(theme => ({
  linkText: {
    fontSize: 16,
    color: '#fff',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
  },
  activeLink: {
    color: colors.red.primary,
  },
}))

const MenuLinks = ({ onClick }) => {
  const classes = useStyles()
  const location = useLocation()
  const routes = useRoutes()
  const matchesMedia = useMediaQuery(theme => theme.breakpoints.down('xs'))

  return (
    <Grid container direction="column" spacing={matchesMedia ? 6 : 2}>
      {
        routes.map((x) => {
          if (x.hidden || !x.component) return null

          return (
            <Grid key={x.path} item>
              <Link to={x.path} onClick={onClick}>
                <Typography
                  className={clsx(
                    classes.linkText,
                    { [classes.activeLink]: x.path === location.pathname },
                  )}
                >
                  {x.label}
                </Typography>
              </Link>
            </Grid>
          )
        })
      }
    </Grid>
  )
}

MenuLinks.propTypes = {
  onClick: PropTypes.func,
}
MenuLinks.defaultProps = {
  onClick: () => {},
}

export default MenuLinks
