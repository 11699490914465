import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Card from 'components/Card'
import CardHover from 'components/Card/CardHover'
import LazyImage from 'components/LazyImage'
import Typography from 'components/Typography'
import SocialMediaBar from 'components/SocialMediaBar'
import createPostURL from 'utils/createPostURL'
import BlogTitle from './BlogTitle'
import BlogDescription from './BlogDescription'
import BlogTagList from './BlogTagList'
import BlogIndustryList from './BlogIndustryList'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 20,
    // marginBottom: 96,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      // marginBottom: 40,
    },
  },
  content: {
    marginLeft: 80,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  postDate: {
    opacity: 0.5,
    [theme.breakpoints.down('sm')]: {
      opacity: 1.0,
    },
  },
  image: {
    height: 240,
  },
  divider: {
    margin: '24px 0px',
  },
}))

const BlogListItem = (props) => {
  const { post, tabIndex } = props
  const classes = useStyles()
  const matchesTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const CreatedAt = (
    <Typography
      variant={matchesTablet ? 'body1' : 'h3'}
      weight="light"
      className={classes.postDate}
    >
      {format(new Date(post.createdAt), 'MM/dd/yy')}
    </Typography>
  )

  const to = createPostURL(post.urlPermalink)

  return (
    <CardHover disabled={matchesTablet}>
      <Card
        className={classes.card}
        to={to}
        tabIndex={tabIndex}
      >
        {
          matchesTablet
            ? null
            : CreatedAt
        }
        <div className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <LazyImage className={classes.image} src={post.heroImage.file.url} alt={post.title} />
            </Grid>
            <Grid item xs={12} md={8}>
              <BlogTitle fontSize={matchesTablet ? 'small' : 'normal'} title={post.title} />
              <BlogIndustryList dark industry={post.industry} />
              {matchesTablet && <Typography className={classes.postDate} variant="caption" weight="light">by {post.author}</Typography>}
              <BlogDescription description={post.subtitle} />
              <Grid container alignItems="center" justify="space-between">
                {matchesTablet && <Grid item>{CreatedAt}</Grid>}
                <Grid item>
                  <SocialMediaBar
                    title={post.title}
                    url={to}
                    hashtags={post.tags}
                    orientation="horizontal"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {matchesTablet && <Divider className={classes.divider} />}
      </Card>
    </CardHover>
  )
}

BlogListItem.propTypes = {}
BlogListItem.defaultProps = {}

export default BlogListItem
