import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import Logo from 'assets/images/logo.png'

import SubscriptionTextField from 'components/SubscriptionTextField'
import SocialMediaBar from 'components/SocialMediaBar'
import GlobalSearch from 'components/GlobalSearch'
import { SIDEBAR_WIDTH, colors } from 'theme'
import MenuLinks from './MenuLinks'

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    minHeight: '100vh',
    width: SIDEBAR_WIDTH,
    backgroundColor: colors.black.primary,
    transition: 'width 250ms, border 250ms',
    zIndex: 100,
    // sticky positioning to the bottom
    position: 'sticky',
    top: 0,
    bottom: 0,
  },
  landingRoot: {
    width: '40%',
    zIndex: 'auto', // need to prevent hero text from instantly showing in front of expanded sidebar
  },
  blogPostPage: {
    width: 0,
    overflow: 'hidden',
  },
  blogPage: {
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  },
  logo: {
    height: 48,
    width: 142,
  },
  content: {
    opacity: 0,
    overflow: 'auto',
    transition: 'opacity 500ms',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 36px 40px 72px',
    alignItems: 'flex-start',
    pointerEvents: 'none',
  },
  showContent: {
    opacity: 1,
    pointerEvents: 'all',
  },
  linksCntr: {
    textAlign: 'left',
    marginTop: 32,
    marginBottom: 16,
  },
  socialIcons: {
    // marginLeft: -12,
    marginTop: 32,
    opacity: 1.0,
    transition: 'opacity 200ms ease-in-out',
  },
  hideSocial: {
    opacity: 0.0,
  },
  globalSearch: {
    marginTop: '30px',
  },
})

const AppBar = (props) => {
  const classes = useStyles()
  const location = useLocation()
  const isSocialHidden = useSelector(({ app }) => app.hideSideSocialBar)

  const matchesLandingPage = location.pathname === '/'
  const matchesBlogPostPage = useRouteMatch('/blog/:id')
  const matchesBlogPage = useRouteMatch('/blog')

  return (
    <div
      className={clsx(
        classes.root,
        { [classes.landingRoot]: matchesLandingPage },
        { [classes.blogPostPage]: matchesBlogPostPage },
        { [classes.blogPage]: matchesBlogPage },
      )}
    >
      <div
        className={clsx(
          classes.content,
          { [classes.showContent]: !matchesLandingPage },
        )}
      >
        <div className={classes.logoCntr}>
          <Link to="/">
            <img className={classes.logo} src={Logo} alt="red-thread-ventures" />
          </Link>
        </div>
        <div className={classes.linksCntr}>
          <MenuLinks />
        </div>
        <SubscriptionTextField
          theme="white"
        />
        <div
          className={clsx(
            classes.socialIcons,
            { [classes.hideSocial]: isSocialHidden },
          )}
        >
          <SocialMediaBar
            follow
            buttonSize="small"
            orientation="horizontal"
            ContainerProps={{ spacing: 1 }}
          />
        </div>
        <div className={classes.globalSearch}>
          <GlobalSearch />
        </div>
      </div>
    </div>
  )
}

AppBar.propTypes = {}
AppBar.defaultProps = {}

export default AppBar
