import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import Typography from 'components/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  hover: {
    transition: 'color 80ms',
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
}))

const ContactInfo = (props) => {
  const { type, className, ...rest } = props
  const classes = useStyles()

  const mapTypeToInfo = {
    email: {
      label: 'investors@redthread.ventures',
      href: 'mailto:investors@redthread.ventures',
    },
    phone: {
      label: '877 241 8444 (Phone)',
      href: 'tel:+18872418444',
    },
    fax: {
      label: '604 357 1362 (Fax)',
      href: 'tel:+16043571362',
    },
    location: {
      label: '375 Water St. #200,\n Vancouver, BC V6B 5C6',
      href: 'https://goo.gl/maps/2n4DY3VCPzeo7Dfu8',
    },
  }

  return (
    <Typography
      component="a"
      href={mapTypeToInfo[type].href}
      target="_blank"
      rel="noopenner noreferrer"
      className={clsx(classes.hover, className)}
      {...rest}
    >
      {mapTypeToInfo[type].label}
    </Typography>
  )
}

ContactInfo.propTypes = {}
ContactInfo.defaultProps = {}

export default ContactInfo
