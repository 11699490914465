import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const Visible = (props) => {
  const { once, options, children, ...rest } = props
  const rootRef = useRef(null)
  const [isVisible, setVisible] = useState(false)

  const defaultOptions = {
    threshold: 0.80,
    ...options,
  }

  useEffect(() => {
    if (rootRef.current) {
      const newObserver = new IntersectionObserver(
        (entries) => {
          const [first] = entries

          if (once) {
            // only trigger once
            if (first.intersectionRatio >= defaultOptions.threshold) {
              setVisible(true)
              newObserver.unobserve(rootRef.current)
            }
          }


          setVisible(first.intersectionRatio >= defaultOptions.threshold)
        },
        defaultOptions,
      )

      newObserver.observe(rootRef.current)
    }
  }, [rootRef])

  return (
    <div {...rest} ref={rootRef}>
      { children({ isVisible }) }
    </div>
  )
}

Visible.propTypes = {
  // only triggers the callback once and when the element is visible
  once: PropTypes.bool,
  options: PropTypes.object,
  // a render function that takes in `isVisible` as an arg
  children: PropTypes.func,
}

Visible.defaultProps = {
  once: false,
  options: {},
  children: ({ isVisible }) => null,
}

export default Visible
