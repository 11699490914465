import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Visible from 'components/Visible'
import AnimatedNode from 'components/AnimatedNode'

const useVisibleStyles = makeStyles({
  visibleWrapper: {
    position: 'relative',
    zIndex: 1,
  },
})

const SlideWhenVisible = ({ children }) => {
  const classes = useVisibleStyles()

  return (
    <div className={classes.visibleWrapper}>
      <Visible once>
        {
          ({ isVisible }) => (
            <AnimatedNode
              show={isVisible}
              direction="down"
            >
              {children}
            </AnimatedNode>
          )
        }
      </Visible>
    </div>
  )
}

export default SlideWhenVisible
