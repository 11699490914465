import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Search from '@material-ui/icons/Search'

const useStyles = makeStyles({
  input: {
    minHeight: 40,
    fontSize: 18,
    fontVariationSettings: '"wght" 300',
  },
  icon: {
    opacity: 0.5,
  },
})

const BlogSearchBar = (props) => {
  const { value, onChange } = props
  const classes = useStyles()

  return (
    <TextField
      fullWidth
      value={value}
      placeholder="Search blog"
      onChange={onChange}
      InputProps={{
        classes: { root: classes.input },
        endAdornment: (
          <InputAdornment position="end">
            <Search className={classes.icon} />
          </InputAdornment>
        ),
      }}
    />
  )
}

BlogSearchBar.propTypes = {}
BlogSearchBar.defaultProps = {}

export default BlogSearchBar
