import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import intersection from 'lodash.intersection'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'components/Typography'
import LazyImage from 'components/LazyImage'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import createPostURL from 'utils/createPostURL'

import SocialMediaBar from 'components/SocialMediaBar'
import BlogTagList from '../components/BlogTagList'
import BlogPreviousNext from '../components/BlogPreviousNext'
import BlogMorePosts from '../components/BlogMorePosts'
import BlogIndustryList from '../components/BlogIndustryList'
import { fetchBlogPost } from '../blog.module'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
  },
  placeholder: {
    minHeight: '100vh',
  },
  bodyRoot: {
    maxWidth: 1000,
    margin: '0 auto -140px',
    backgroundColor: '#fff',
    transform: 'translateY(-140px)',
    [theme.breakpoints.down('sm')]: {
      transform: 'translateY(-48px)',
      margin: '0px 20px -48px',
    },
  },
  body: {
    padding: '40px 100px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0px',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 32,
    },
  },
  tags: {
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16,
    },
  },
  createdAt: {
    marginTop: 40,
    fontWeight: 300,
    fontFamily: 'Pangram',
    opacity: 0.7,
    [theme.breakpoints.down('sm')]: {
      marginTop: 16,
    },
  },
  backButton: {
    borderRadius: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 40,
    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  footer: {
    padding: '60px 100px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0px 0px',
    },
  },
  heroImage: {
    maxHeight: 500,
  },
}))

const BlogPage = (props) => {
  const { match, history, posts } = props
  const [post, setPost] = useState(null)
  const [morePosts, setMorePosts] = useState([])
  const [prev, setPrev] = useState(null)
  const [next, setNext] = useState(null)

  const postUrlPermalink = match.params.title

  const classes = useStyles()
  const matchesTablet = useMediaQuery(theme => theme.breakpoints.down('sm'))

  useEffect(() => {
    if (postUrlPermalink) {
      setPost(null)
      const getPost = async () => {
        const res = await fetchBlogPost(postUrlPermalink)

        try {
          setPost({
            ...res.fields,
            id: res.sys.id,
            createdAt: res.sys.createdAt,
            heroImage: res.fields.heroImage.fields,
            industry: res.fields.industry || [],
            tags: res.fields.tags || [],
          })
        } catch (err) {
          console.log(err)
          history.push('/blog')
        }
      }

      getPost()
    }
  }, [postUrlPermalink])

  useEffect(() => {
    const more = []

    if (!post) return

    posts.forEach((x, i) => {
      // getting similar posts
      const hasSimilar = intersection(post.industry, x.industry).length

      if (hasSimilar && (x.id !== post.id)) {
        more.push(x)
      }

      // find previous and next posts
      if (x.urlPermalink === postUrlPermalink) {
        // sorted by newest to oldest
        setPrev(posts[i + 1])
        setNext(posts[i - 1])

        if (i === 0) {
          // first post so no next post
          setNext(null)
        }

        if (i === posts.length) {
          // last post so no previous
          setPrev(null)
        }
      }
    })

    if (more.length) {
      setMorePosts(more)
    } else {
      // no similar posts, need to select recent 3
      const uniquePosts = posts.filter(x => x.id !== post.id)
      setMorePosts(uniquePosts.slice(uniquePosts.length - 3, uniquePosts.length))
    }
  }, [post, posts])

  if (!post) return <div className={classes.placeholder} />

  const getRandomIntInclusive = (min, max) =>  {
    const rangeMin = Math.ceil(min)
    const rangeMax = Math.floor(max)
    return Math.floor(Math.random() * (rangeMax - rangeMin + 1) + rangeMin) // The maximum is inclusive and the minimum is inclusive
  }

  return (
    <div className={classes.root}>
      <LazyImage className={classes.heroImage} src={post.heroImage.file.url} alt={post.title} />
      <div className={classes.bodyRoot}>
        <div className={classes.body}>
          <div className={classes.header}>
            <Link to="/blog">
              <IconButton className={classes.backButton}>
                <ArrowBack fontSize={matchesTablet ? 'default' : 'large'} />
              </IconButton>
            </Link>
            <div className={classes.tags}>
              <BlogTagList tags={post.tags} />
            </div>
            <Typography variant={matchesTablet ? 'h3' : 'h1'} weight="bold">{post.title}</Typography>
            <BlogIndustryList dark industry={post.industry} />
            <Typography variant="h3" className={classes.createdAt}>{format(new Date(post.createdAt), 'MM/dd/yyyy')}</Typography>
          </div>
          <div>
            {post.body.map(x => x)}
          </div>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography weight="light">Author <Typography component="span" weight="bold">{post.author}</Typography></Typography>
            </Grid>
            <Grid item>
              <SocialMediaBar
                title={post.title}
                hashtags={post.tags}
                url={createPostURL(post.urlPermalink)}
                orientation="horizontal"
              />
            </Grid>
          </Grid>
        </div>
        <Divider />
        <div className={classes.footer}>
          <BlogPreviousNext
            previousPost={prev}
            nextPost={next}
          />
          <BlogMorePosts posts={morePosts.slice(0, 3)} />
        </div>
      </div>
    </div>
  )
}

BlogPage.propTypes = {}
BlogPage.defaultProps = {}

export default BlogPage
