/**
 * Loads an image source as a background-image that will center and cover
 * a container of any size
 */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  modalBody: {
    outline: 'none',
  },
  root: ({ src, width, height }) => ({
    height: height ?? '100%',
    width: width ?? '100%',
    backgroundImage: `url(${src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }),
  modalImage: ({ src }) => ({
    height: '95vh',
    width: '100%',
    marginTop: '2.5vh',
    backgroundImage: `url(${src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  }),
  closeIcon: {
    position: 'fixed',
    top: 16,
    right: 16,
  },
}))

const Image = (props) => {
  const { src, width, height, popup = false } = props
  const classes = useStyles({ src, width, height })
  const [open, setOpen] = useState(false)

  const handleOnClick = () => {
    if (popup) {
      setOpen(true)
    }
  }

  return (
    <>
      <div className={classes.root} onClick={handleOnClick} />
      <Modal
        open={open}
        onClose={() => { setOpen(false) }}
      >
        <div className={classes.modalBody}>
          <div className={classes.modalImage} />
          <div className={classes.closeIcon}>
            <IconButton>
              <Close />
            </IconButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

Image.propTypes = {}
Image.defaultProps = {}

export default Image

