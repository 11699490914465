import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useCountUp } from 'react-countup'
import '@brainhubeu/react-carousel/lib/style.css'
import Carousel from '@brainhubeu/react-carousel'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from 'components/Typography'
import IconButton from '@material-ui/core/IconButton'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from 'components/Button'
import AnimatedText from 'components/AnimatedText'
import AnimatedNode from 'components/AnimatedNode'
import SlideWhenVisible from 'components/SlideWhenVisible'

import Line1 from 'assets/images/line-1.png'
import Line2 from 'assets/images/line-2.png'
import Line3 from 'assets/images/line-3.png'
import Line5 from 'assets/images/line-5.png'
import ResourceAbundance from 'assets/images/ResourceAbundance.svg'
import ThinkGlobal from 'assets/images/ThinkGlobal.svg'
import Accelerate from 'assets/images/Accelerate.svg'
import Alignment from 'assets/images/Alignment.svg'
import ControlFate from 'assets/images/ControlFate.svg'
import CarouselItemBackground from 'assets/images/aboutUsMobileBG.png'
import CityBG from 'assets/images/landing-background.jpeg'

import { colors } from 'theme'

const useStatStyles = makeStyles(theme => ({
  primary: {
    fontSize: 64,
    fontFamily: 'Pangram',
    fontWeight: 300,
    textShadow: '3px 3px 10px rgba(255, 88, 88, 0.37)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
    },
  },
  secondary: {
    fontSize: 12,
    lineHeight: 2,
    opacity: 0.7,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
}))

const Statistic = ({ primary, suffix = null, secondary }) => {
  const classes = useStatStyles()
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { countUp } = useCountUp({
    end: primary,
    suffix,
    delay: 1.5,
  })

  return (
    <Grid container direction="column" alignItems={matchesMobile ? 'flex-start' : 'center'}>
      <Grid item>
        <Typography color="textSecondary" className={classes.primary}>{countUp}</Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.secondary} weight="light">{secondary}</Typography>
      </Grid>
    </Grid>
  )
}

const useThreadStyles = makeStyles({
  root: ({ margin }) => ({
    width: '100%',
    marginTop: margin[0],
    right: margin[1],
    marginBottom: margin[2],
    left: margin[3],
    position: 'relative',
  }),
})

const ThreadGraphic = ({ image, margin = [0, 0, 0, 0], width = '100%', height = '100%' }) => {
  const classes = useThreadStyles({ image, margin })

  return (
    <div className={classes.root}>
      <img src={image} className={classes.image} width={width} height={height} alt="thread-of-fate" />
    </div>
  )
}

const useSectionStyles = makeStyles(theme => ({
  title: {
    fontSize: 'clamp(30px, 5vw, 32px)',
    fontVariationSettings: '"wght" 700',
  },
  challenge: {
    fontSize: 18,
    fontVariationSettings: '"wght" 700',
    marginBottom: 24,
  },
  description: {
    lineHeight: 2,
    opacity: 0.7,
  },
  image: {
    height: 250,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 32,
    },
  },
}))

const InfoSection = ({ image, title, challenge, description, direction = 'row' }) => {
  const classes = useSectionStyles()
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <Grid container alignItems="center" justify="space-between" direction={direction}>
      {
        !matchesMobile && (
          <Grid item sm={12} md={4}>
            <div className={classes.image}>
              <img src={image} width="100%" height="100%" alt={title} />
            </div>
          </Grid>
        )
      }
      <Grid container item sm={12} md={6} direction="column">
        <Grid item>
          <Typography color="textSecondary" className={classes.title}>{title}</Typography>
          <Typography className={classes.challenge}>{challenge}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.description} weight="light">{description}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
  },
  orderContainer: {
    display: 'flex',
    flexDirection: 'column',
    // [theme.breakpoints.down('sm')]: {
    //   flexDirection: 'column-reverse',
    // },
  },
  headerContainer: {
    padding: '130px 96px 96px',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 32px 40px',
    },
  },
  infoContainer: {
    padding: '32px 96px',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 24px',
    },
  },
  widthContainer: {
    maxWidth: 900,
    margin: '0 auto',
  },
  headerTextCntr: {
    marginBottom: 48,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 24,
    },
  },
  mobileTitle: {
    display: 'none',
    fontSize: 18,
    opacity: 0.4,
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
      marginBottom: 24,
    },
  },
  title: {
    lineHeight: 1,
    fontVariationSettings: '"wght" 700',
    fontSize: 28,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  mainHeader: {
    marginBottom: 40,
  },
  desc1: {
    marginBottom: 32,
  },
  titleDesc: {
    lineHeight: 1.4,
    // fontVariationSettings: '"wght" 300',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  subtitleCntr: {
    marginTop: 32,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 32,
    },
  },
  subtitle: {
    maxWidth: 600,
    lineHeight: 2,
    fontVariationSettings: '"wght" 300',
    opacity: 0.7,
    [theme.breakpoints.down('sm')]: {
      fontVariationSettings: '"wght" 400',
      lineHeight: 1.5,
      opacity: 1,
      fontSize: 16,
    },
  },
  communityText: {
    lineHeight: 1.67,
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  statsBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginTop: 48,
    },
  },
  stat: {
    width: 160,
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  statsDivider: {
    margin: '0 76px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 32px',
    },
  },
  controlFateBox: {
    marginBottom: 160,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  controlFateLogoCntr: {
    marginBottom: 52,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 24,
    },
  },
  controlFateLogo: {
    maxHeight: 196,
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  controlFateText: {
    fontSize: 18,
    fontVariationSettings: '"wght" 700',
    textAlign: 'center',
  },
  slackBox: {
    position: 'relative',
    zIndex: 1,
  },
  carouselBox: {
    position: 'relative',
  },
  carousel: {
    position: 'relative',
    zIndex: 10,
  },
  carouselBg: {
    position: 'absolute',
    zIndex: 5,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${CarouselItemBackground})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  carouselItem: {
    padding: '60px 40px',
  },
  carouselDotsBox: {
    paddingBottom: 60,
    margin: (3 * 8) / 2, // needs to match 1/2 of the child's calculated Grid.spacing
    marginBottom: 0,
  },
  carouselDot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: colors.red.primary,
    opacity: 1,
    transition: 'opacity 250ms ease-in-out',
  },
  activeCarouselDot: {
    opacity: 0.2,
  },
  cityCover: {
    height: 106,
    backgroundImage: `url(${CityBG})`,
    backgroundPosition: 'top -88px center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    width: '100%',
    '&:before': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: colors.red.primary,
      opacity: 0.53,
      content: '""',
    },
  },
  investors_m: {
    padding: '96px 30px',
  },
}))

const AboutUsData = [
  {
    image: Accelerate,
    title: 'Accelerate',
    challenge: 'Business success often depends more on "who you know" than "what you know".',
    description: 'Once you have a good network it can be very financially rewarding as you build trust and support each others\' deal flow and projects. Red Thread helps accelerate this networking process exponentially through a mutually beneficial co-ownership relationship providing a financial incentive to help support fellow members\' business opportunities.',
  },
  {
    image: Alignment,
    title: 'Alignment',
    challenge: 'Management compensation needs to align with investor expectations.',
    description: 'Red Thread is actively involved in the operations of investee businesses. We align with founders not only as investors but also as operators within their business. This unique vantage point allows us to truly understand the ins and outs of investee businesses, while at the same time aligning expectations.',
  },
  {
    image: ResourceAbundance,
    title: 'Resource Abundance',
    challenge: 'Traditional reporting structures rarely properly leverage the resources of good investors.',
    description: 'Our operational role within investee businesses gives us the ability to understand financial health at an intimate level.',
  },
  {
    image: ThinkGlobal,
    title: 'Think Global',
    challenge: 'Business success requires reaching beyond local borders',
    description: 'We embrace global investment and business development opportunities.',
  },
]

const MainHeader = 'Red Thread Ventures (RTV) is a global investment and advisory community.'
const AboutUsMainDescription = 'RTV forms long term partnerships with early stage companies led by incredible management teams across a variety of industries. Besides capital, Red Thread adds value by leveraging an extensive CFO and capital markets executive network to aid ventures as they scale from idea to IPO and beyond.'
const OurGoalDescription = 'Our goal is to generate outstanding returns for member investors while helping build strong international business connections between participating members. We do this by providing solutions to 4 common problems.'

const AboutUs = (props) => {
  const [slide, setSlide] = useState(0)
  const classes = useStyles()
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const AboutUsMainDescription2 = (
    <div className={classes.titleDesc}>
      Red Thread Ventures currently manages two investment funds:
      <ul>
        <li>A VCC (Venture Capital Corporation) fund focused on pre-seed/seed stage opportunities in British Columbia</li>
        <li>A &apos;Global Fund&apos; for follow-on investments and opportunities across different geographies and asset classes.</li>
      </ul>
    </div>
  )

  const AccelerateSection = (
    <InfoSection {...AboutUsData[0]} direction="row-reverse" />
  )

  const AlignmentSection = (
    <InfoSection {...AboutUsData[1]} direction="row-reverse" />
  )

  const ResourceAbundanceSection = (
    <InfoSection {...AboutUsData[2]} />
  )

  const ThinkGlobalSection = (
    <InfoSection {...AboutUsData[3]} />
  )

  const ControlFateSection = (
    <div className={classes.controlFateBox}>
      <div className={classes.controlFateLogoCntr}>
        <img src={ControlFate} alt="Control Fate" className={classes.controlFateLogo} />
      </div>
      <Typography className={classes.controlFateText}>
        To implement these solutions, we are borrowing the legend of the “Red Thread” that purports that there is an imaginary thread connecting people destined to meet, bound by fate itself. Our goal is to &quot;make fate&quot; happen by leveraging our expertise and addressing these challenges head-on with the expectation that investee companies will perform better as a result.
      </Typography>
    </div>
  )

  const InvestorsSection = (
    <div className={classes.slackBox}>
      <Grid container>
        <Grid item sm={2} />
        <Grid item sm={8}>
          <Grid container direction="column" spacing={4} alignItems="center">
            <Grid item>
              <Typography align="center" className={classes.communityText}>
                {'Join our "Members Only" Slack channel to discuss and source deals'}
              </Typography>
            </Grid>
            <Grid item>
              <Link to="/investor-network">
                <Button variant="secondary">see more</Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )

  const NUM_SECTIONS = 5

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography className={classes.mobileTitle}>About Us</Typography>
        <div className={clsx(classes.widthContainer, classes.orderContainer)}>
          <div>
            <div className={classes.headerTextCntr}>
              <div className={classes.mainHeader}>
                <AnimatedText className={clsx(classes.title)}>
                  {MainHeader}
                </AnimatedText>
              </div>
              <div className={classes.desc1}>
                <AnimatedText className={classes.titleDesc}>
                  {AboutUsMainDescription}
                </AnimatedText>
              </div>
              <AnimatedNode>
                {AboutUsMainDescription2}
              </AnimatedNode>
            </div>
            <div className={classes.statsBox}>
              <div className={classes.stat}>
                <Statistic
                  primary={9}
                  secondary="Countries with investor representation"
                />
              </div>
              <div className={classes.statsDivider}>
                <Divider orientation="vertical" />
              </div>
              <div className={classes.stat}>
                <Statistic
                  primary={26}
                  suffix="%"
                  secondary="Expected financial return annually"
                />
              </div>
            </div>
          </div>
          <div className={classes.subtitleCntr}>
            <Typography className={classes.subtitle}>
              {OurGoalDescription}
            </Typography>
          </div>
        </div>
      </div>
      {!matchesMobile && <Divider />}
      {
        matchesMobile
          ? (
            <div>
              <div className={classes.carouselBox}>
                <div className={classes.carousel}>
                  <Carousel
                    value={slide}
                    onChange={(value) => { setSlide(value % NUM_SECTIONS) }}
                    keepDirectionWhenDragging
                  >
                    <div className={classes.carouselItem}>{AccelerateSection}</div>
                    <div className={classes.carouselItem}>{AlignmentSection}</div>
                    <div className={classes.carouselItem}>{ResourceAbundanceSection}</div>
                    <div className={classes.carouselItem}>{ThinkGlobalSection}</div>
                    <div className={classes.carouselItem}>{ControlFateSection}</div>
                  </Carousel>
                  <div className={classes.carouselDotsBox}>
                    <Grid container spacing={3} justify="center">
                      {
                        new Array(NUM_SECTIONS).fill(null).map((_, i) => (
                          <Grid key={`dot-${i}`} item>
                            <IconButton onClick={() => { setSlide(i) }}>
                              <div
                                className={clsx(
                                  classes.carouselDot,
                                  { [classes.activeCarouselDot]: slide === i },
                                )}
                              />
                            </IconButton>
                          </Grid>
                        ))
                      }
                    </Grid>
                  </div>
                </div>
                <div className={classes.carouselBg} />
              </div>
              <div>
                <div className={classes.investors_m}>
                  <SlideWhenVisible>
                    {InvestorsSection}
                  </SlideWhenVisible>
                </div>
                {/* <div className={classes.cityCover} /> */}
              </div>
            </div>
          )
          : (
            <div className={classes.infoContainer}>
              <div className={classes.widthContainer}>
                <ThreadGraphic image={Line1} margin={[0, 0, -30, '50%']} width={160} height={170} />
                <SlideWhenVisible>
                  {AccelerateSection}
                </SlideWhenVisible>
                <ThreadGraphic image={Line2} margin={[-30, 0, -30, '25%']} width={341.5} height={263.8} />
                <SlideWhenVisible>
                  {ResourceAbundanceSection}
                </SlideWhenVisible>
                <ThreadGraphic image={Line3} margin={[-30, 0, -30, '23%']} width={459.5} height={283.5} />
                <SlideWhenVisible>
                  {AlignmentSection}
                </SlideWhenVisible>
                <ThreadGraphic image={Line2} margin={[-20, 0, -20, '30%']} width={299} height={214.2} />
                <SlideWhenVisible>
                  {ThinkGlobalSection}
                </SlideWhenVisible>
                <ThreadGraphic image={Line3} margin={[-20, 0, -20, '23%']} width={500} height={283.5} />
                <SlideWhenVisible>
                  {ControlFateSection}
                </SlideWhenVisible>
                <SlideWhenVisible>
                  {InvestorsSection}
                </SlideWhenVisible>
                <ThreadGraphic image={Line5} margin={[-130, 0, 0, '35%']} width={1102} height={353.3} />
              </div>
            </div>
          )
      }
    </div>
  )
}

AboutUs.propTypes = {}
AboutUs.defaultProps = {}

export const dataForGlobalSearch = () => {
  const data = AboutUsData.map((x) => ({
    title: x.title,
    content: `${x.challenge} ${x.description}`,
    path: '/about-us',
  }))

  data.push({
    title: 'About Us',
    content: AboutUsMainDescription,
    path: '/about-us',
  })

  return data
}

export default AboutUs
