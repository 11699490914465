import React, { useEffect } from 'react'
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom'
import clsx from 'clsx'
import { connect, useDispatch } from 'react-redux'
import Footer from 'components/Footer'
import AppBar from 'components/AppBar'
import ScrollToTop from 'components/ScrollToTop'
import PageTracker from 'components/PageTracker'
import MobileAppBar from 'components/AppBar/MobileAppBar'
import useRoutes from 'utils/useRoutes'
import { makeStyles } from '@material-ui/core/styles'
import Fade from '@material-ui/core/Fade'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { SIDEBAR_WIDTH, MOBILE_HEADER_HEIGHT } from 'theme'
import { actions } from './app.module'

const useStyles = makeStyles({
  stickyOffset: {
    // 604px is sidebar height and therefore the min offset
    marginTop: 'min(-602px, -100vh)',
  },
  landingAnchor: {
    position: 'relative',
  },
  mobileHeaderOffset: {
    marginTop: MOBILE_HEADER_HEIGHT,
  },
  appBarOffset: {
    paddingLeft: SIDEBAR_WIDTH,
  },
  noOffset: {
    paddingLeft: 0,
  },
})

const Routes = () => {
  const classes = useStyles()
  const routes = useRoutes()
  const dispatch = useDispatch()
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  useEffect(() => {
    dispatch(actions.checkLastPopupDate())
  }, [])

  return (
    <Router>
      <div>
        <div className={classes.landingAnchor}>
          <Route component={ScrollToTop} />
          <Route component={PageTracker} />
          {
            matchesMobile
              ? <MobileAppBar />
              : <AppBar />
          }
          <div
            className={clsx(
              { [classes.stickyOffset]: !matchesMobile },
              { [classes.mobileHeaderOffset]: matchesMobile },
            )}
          >
            <Switch>
              {
                routes.map(x => (
                  <Route
                    key={x.path}
                    path={x.path}
                    exact={x.exact}
                    render={(props) => {
                      if (!x.component) return <Redirect to="/" />

                      const matchesPath = props.location.pathname === x.path
                      const matchesNestedPath = props.location.pathname.includes(x.path)
                      const matchesBlogPage = props.location.pathname.includes('/blog')

                      return (
                        <Fade
                          in={matchesPath || matchesNestedPath}
                          timeout={500}
                          style={{ transitionDelay: '550ms' }}
                        >
                          <div
                            className={clsx(
                              { [classes.appBarOffset]: !matchesMobile && (x.path !== '/') },
                              { [classes.noOffset]: !matchesPath && matchesBlogPage },
                            )}
                          >
                            <x.component {...props} />
                          </div>
                        </Fade>
                      )
                    }}
                  />
                ))
              }
              <Redirect to="/" />
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  )
}

const mapStateToProps = (state) => ({
  checked: state.app.checked,
  loggedIn: state.app.loggedIn,
})

export default connect(mapStateToProps)(Routes)
