import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Typography from 'components/Typography'
import Button from 'components/Button'
import SubscribeIcon from 'assets/images/subscribe.png'
import SubscribeWhite from 'assets/images/subscribeWhite.png'
import { actions } from '../../app.module'

const useStyles = makeStyles((theme) => ({
  emailGridItem: {
    flex: 1,
  },
  emailField: {
    marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  declineButton: {
    marginRight: 32,
  },
  inputColor: {
    color: '#fff',
  },
  textRoot: {
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
  },
}))

const FORM_ACTION_URL = 'https://ventures.us18.list-manage.com/subscribe/post?u=1a595966e02447c93dd291467&amp;id=5c275ce10b'

const SubscriptionTextField = (props) => {
  const { minimized, theme, onClose, onSuccess, onStatusChange } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [helperText, setHelperText] = useState('')
  const [subStatus, setSubStatus] = useState(null)

  useEffect(() => {
    if (subStatus === 'success') {
      dispatch(actions.setSubscribed(true))

      gtag(
        'event',
        'subscription',
        {
          event_category: 'new_subscription',
        },
      ) // eslint-disable-line
      const id = setTimeout(() => {
        setSubStatus(null)
        setEmail('')
        clearTimeout(id)
      }, 3500)

      onSuccess()
    }

    if (subStatus === 'duplicate') {
      dispatch(actions.setSubscribed(true))
    }
    onStatusChange(subStatus)
  }, [subStatus])

  const validateEmail = () => {
    const regex = RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/) // eslint-disable-line
    if (!regex.test(email)) {
      setHelperText('Please enter a valid email')

      const id = setTimeout(() => {
        setHelperText('')

        clearTimeout(id)
      }, 3500)
    }
  }

  const handleSubscriptionClick = (subscribe) => {
    setHelperText('')
    validateEmail(email)
    if (helperText) return

    try {
      subscribe({ EMAIL: email })
    } catch (err) {
      console.log(err)
    }
  }

  const onChange = (e) => {
    setEmail(e.target.value)
    setHelperText('')
  }

  const parseMessage = (status, m) => {
    if (m?.includes('already subscribed')) {
      return 'You are already subscribed!'
    }

    if (status === 'error') return 'Please try again later.'

    return m
  }

  if (subStatus === 'success') return <Typography color="secondary">Subscribed!</Typography>

  return (
    <MailchimpSubscribe
      url={FORM_ACTION_URL}
      render={
        ({ subscribe, status, message }) => {
          if (status === 'success') setSubStatus(status)
          if (message?.includes('already subscribed')) {
            setSubStatus('duplicate')
          }

          const WhiteField = (
            <TextField
              placeholder="Subscribe"
              onChange={onChange}
              value={email}
              classes={{ root: classes.textRoot }}
              InputProps={{
                classes: {
                  root: classes.inputColor,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => { handleSubscriptionClick(subscribe) }}>
                      <img src={SubscribeWhite} alt="Subscribe to Red Thread Ventures" width={24} height={24} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={helperText || parseMessage(status, message)}
            />
          )

          const MinimizedField = (
            <TextField
              fullWidth
              placeholder="Email"
              onChange={onChange}
              value={email}
              classes={{ root: classes.emailField }}
              helperText={helperText || parseMessage(status, message)}
            />
          )

          if (theme === 'white') return WhiteField

          return (
            minimized
              ? (
                <>
                  <Grid container alignItems="center">
                    <Grid item classes={{ item: classes.emailGridItem }}>
                      {MinimizedField}
                    </Grid>
                    <Grid item>
                      <IconButton onClick={() => { handleSubscriptionClick(subscribe) }}>
                        <img src={SubscribeIcon} alt="Subscribe to Red Thread Ventures Blog" width={36} height={36} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              )
              : (
                <>
                  {MinimizedField}
                  <Button className={classes.declineButton} variant="secondary" onClick={onClose}>
                    <Typography variant="h4">No Thanks</Typography>
                  </Button>
                  <Button onClick={() => { handleSubscriptionClick(subscribe) }}>
                    <Typography variant="h4">Subscribe</Typography>
                  </Button>
                </>
              )
          )
        }
      }
    />
  )
}

SubscriptionTextField.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  onStatusChange: PropTypes.func,
}
SubscriptionTextField.defaultProps = {
  onSuccess: () => {},
  onClose: () => {},
  onStatusChange: (subStatus) => subStatus,
}

export default SubscriptionTextField
