/**
 * Adds a text badge to the top right of the child container.
 * This component will expand to fill the width and height of the child container.
 */
import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  parent: {
    position: 'relative',
    display: 'inline-block',
    '&:after': {
      content: '""',
      bottom: 0,
      left: 0,
      width: '100%',
      position: 'absolute',
      height: '50%',
      minHeight: 100,
      backgroundImage: 'linear-gradient(to bottom, rgba(45, 45, 45, 0), #121212)',
    },
  },
  fullWidth: {
    width: '100%',
  },
  badge: {
    // show the bookmark icon and text on the bottom left when on mobile
    position: 'absolute',
    zIndex: 1,
    top: 'unset',
    right: 'unset',
    backgroundColor: 'unset',
    backdropFilter: 'unset',
    bottom: 24,
    left: 20,
    padding: 0,
  },
  text: {
    color: '#fff',
  },
  title: {
    fontWeight: 'bold',
  },
}))

const ContainerBadge = (props) => {
  const { text, description, fullWidth, additionalInfo, children } = props
  const classes = useStyles()

  return (
    <div
      className={clsx(
        classes.parent,
        // when children has no defined width
        { [classes.fullWidth]: fullWidth },
      )}
    >
      <div className={classes.badge}>
        <Typography className={classes.text} variant="h4" gutterBottom>Feature</Typography>
        <Typography className={clsx(classes.text, classes.title)} gutterBottom variant="h2">{text}</Typography>
        <Typography className={classes.text}>{description}</Typography>
        {additionalInfo}
      </div>
      {children}
    </div>
  )
}

ContainerBadge.propTypes = {}
ContainerBadge.defaultProps = {}

export default ContainerBadge
